<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" lg="6" class="d-flex filters pt-2 search-padding">
        <div v-if="showSearch" class="search-input">
          <base-input
            class="search-input font-family-2"
            type="text"
            solo
            flat
            dense
            sm
            hide-details
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="contentKitFilters.search"
            @change="filtersUpdated"
          >
          </base-input>
        </div>
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <div v-if="showSearch" class="type-filter">
            <v-select
              :items="types"
              label="Type"
              solo
              flat
              dense
              item-text="name"
              item-value="id"
              :hide-details="true"
              v-model="contentKitFilters.selectedTypes"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="type-filter"
              background-color="transparent"
              @change="filtersUpdated"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Type </span>
              </template>
            </v-select>
          </div>
          <div v-if="showSearch" class="dimension-filter">
            <v-select
              :items="dimensions"
              label="Dimension"
              solo
              flat
              dense
              item-value="id"
              item-text="name"
              :hide-details="true"
              v-model="contentKitFilters.selectedDimensions"
              @change="filtersUpdated"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="dimension-filter"
              background-color="transparent"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Dimension </span>
              </template>
            </v-select>
          </div>
          <div v-if="showSearch" class="platform-filter">
            <v-select
              :items="socialTags"
              label="Platform"
              solo
              flat
              dense
              item-value="id"
              item-text="name"
              :hide-details="true"
              v-model="contentKitFilters.selectedPlatforms"
              @change="filtersUpdated"
              multiple=""
              :menu-props="{'content-class' : 'filter-dropdown', bottom: true, offsetY: true}"
              class="platform-filter"
              background-color="transparent"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="selected"> Platform </span>
              </template>
            </v-select>
          </div>
          <div class="clear-filter mt-1 ml-2" v-if="anyFilter">
            <v-btn small text class="pa-0"
              @click="clearFilters" color="info">
              Clear All
            </v-btn>
          </div>
        </template>
        <template v-else>
          <template class="filter-icon" v-if="showSearch">
          <v-btn icon v-if="anyFilter" @click="mobileFilters = !mobileFilters" class="ml-auto">
            <v-img src="@/assets/svg/theme/filter-icon-selected.svg"
              contain width="20" height="20"/>
          </v-btn>
          <v-btn v-else icon @click="mobileFilters = !mobileFilters" class="ml-auto">
            <v-img src="@/assets/svg/theme/filter-icon.svg" contain width="20" height="20"/>
          </v-btn>
          </template>

            <v-btn v-if="showSearch" color="white" depressed min-width="40"
              class="heart-icon fav-btn ml-1 px-2"
              :class="{'favorite-selected': contentKitFilters.favoriteItems}"
              @click="toggleFavorites"
              @change="filtersUpdated"
            >
              <v-icon :color="contentKitFilters.favoriteItems ? 'white' : 'black'">
                mdi-heart-outline
              </v-icon>
            </v-btn>
            <template v-if="canAddKit" class="d-flex justify-end pt-0">
              <v-btn
                color="info"
                height=36
                width=5
                class="px-8 ml-2"
                @click="$emit('new-content-kit')"
              >
                New
              </v-btn>
            </template>
        </template>
      </v-col>
      <v-col cols="12" lg="6" class="d-flex justify-end pt-0"
        :class="{'pb-0': $vuetify.breakpoint.mdAndDown}"
      >
        <div class="ml-auto d-flex justify-end">
          <div class="d-none d-md-flex">
            <template
              v-if="kitView"
            >
              <div class="sort-label pr-2 font-family-2">  Sort By: </div>
              <div class="sort-items">
                <v-select
                  :items="sortByItems"
                  solo
                  flat
                  dense
                  :hide-details="true"
                  v-model="contentKitFilters.sortBy"
                  item-value="value"
                  item-text="text"
                  :menu-props="{'content-class' : 'filter-dropdown',
                    bottom: true, offsetY: true, flat: true}"
                  class="sort-dropdown"
                  background-color="transparent"
                >
                </v-select>
              </div>
              <div class="asset-view">
                <v-btn icon v-if="contentKitFilters.gridView"
                  @click="contentKitFilters.gridView = !contentKitFilters.gridView"
                >
                  <v-img
                    src="@/assets/svg/theme/list-view-icon.svg" contain width="18" height="18"
                  />
                </v-btn>
                <v-btn icon v-else
                  @click="contentKitFilters.gridView = !contentKitFilters.gridView"
                >
                  <v-img
                    src="@/assets/svg/theme/grid-view-icon.svg" contain width="20" height="20"
                  />
                </v-btn>
              </div>
            </template>
            <v-btn v-if="showSearch" color="white" depressed min-width="40"
              class="fav-btn ml-1 px-2"
              :class="{'favorite-selected': contentKitFilters.favoriteItems}"
              @click="toggleFavorites"
              @change="filtersUpdated"
            >
              <v-icon :color="contentKitFilters.favoriteItems ? 'white' : 'black'">
                mdi-heart-outline
              </v-icon>
            </v-btn>
            <template v-if="canAddKit" class="d-flex justify-end pt-0">
              <v-btn
                color="info"
                height=36
                class="px-8 ml-2"
                @click="$emit('new-content-kit')"
              >
                New
              </v-btn>
            </template>
          </div>
          <template v-if="!customer">
            <div class="files-filter pl-2" v-if="currentContentKit.id">
              <v-select
                :items="filterItems"
                label="Solo field"
                dense
                solo
                flat
                class=''
                value='1'
                hide-details
                @change="activeFilterChange"
              ></v-select>
            </div>
            <Upload
              id="upload-documents"
              class="upload-button text-center ml-2"
              multiple
              mode="justImage"
              @change="uploadContentKits"
              v-if="isProjectActive && canUploadAssets"
              :acceptedFiles="'.jpg,.jpeg,.png,.pdf,.gif,.ai,.psd,.mp4,.mov,.doc,.docx,.srt'"
            >
              <template>
                <img src="@/assets/svg/theme/upload.svg" />
                <span class="ml-1 add-text h-100">Add</span>
              </template>
            </Upload>
          </template>
        </div>
      </v-col>
    </v-row>
    <v-container
      fluid
      v-if="mobileFilters"
      background-color="white"
    >
    <Modal
      persistent
      fullscreen
      content-class="asset-filters-modal"
      :modal="mobileFilters"
      width="400"
      light
      background-color="white"
      >
        <div class="heading">
        <v-row class="ma-0 px-4 pb-2">
          <v-col class="d-flex align-center pb-0" cols="11">
            <div class="text-capitalize input-field-label"> Filters</div>
          </v-col>
          <v-col class="d-flex justify-end pb-0 px-0" cols="1">
            <v-icon color="darken-1" @click="closeFilters">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="pa-0"> <v-divider> </v-divider> </v-col>
        </v-row>
        </div>
        <div class="filters-list mt-2">
          <v-row class="ma-0 px-4 pb-2" v-if="kitView">
            <v-col class="pt-0" cols="12">
              <div class="sort-title"> Sort By </div>
              <v-radio-group
                v-model="mobileSelectedFilters.sortBy"
                row
                hide-details
              >
                <v-radio
                  v-for="item in sortByItems"
                  :key="item.text"
                  :label="item.text"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="py-0"> <v-divider> </v-divider> </v-col>
          </v-row>
          <v-row class="ma-0 px-4 pb-2">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Type"
                :options="types"
                name="contentTypeIds"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedTypes"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
            <v-col cols="12" class="py-0"> <v-divider> </v-divider> </v-col>
          </v-row>
          <v-row class="ma-0 px-4 pb-2">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Dimension"
                :options="dimensions"
                name="selectedDimensions"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedDimensions"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
            <v-col cols="12" class="py-0"> <v-divider> </v-divider> </v-col>
          </v-row>
          <v-row class="ma-0 px-4 pb-2">
            <v-col class="py-0" cols="12">
              <CustomCheckboxGroup
                label="Platform"
                :options="socialTags"
                name="selectedPlatforms"
                item-text="name"
                item-value="id"
                v-model="mobileSelectedFilters.selectedPlatforms"
                hide-details
              >
              </CustomCheckboxGroup>
            </v-col>
          </v-row>
          <div class="actions">
            <v-divider> </v-divider>
            <v-row class="pr-4 pb-0 ma-0">
              <v-col cols="6" class="d-flex align-items-center">
                <v-btn small @click="resetMobileFilters" text> Clear All </v-btn>
              </v-col>
              <v-col class="d-flex justify-end px-0" cols="6">
                <v-btn small class="primary" @click="applyFilters">Apply</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import axios from 'axios';
import EventBus from '@/helpers/event-bus';
import BaseInput from '@/components/common/BaseInput';
import CustomCheckboxGroup from '@/components/common/CustomCheckboxGroup';
import Modal from '@/components/common/Modal';
import Upload from '@/components/common/Upload';

export default {
  components: {
    BaseInput,
    CustomCheckboxGroup,
    Modal,
    Upload,
  },
  props: {
    canAddKit: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('contentKit', ['currentContentKit', 'dimensions', 'socialTags']),
    ...mapGetters('project', ['currentProject']),
    ...mapGetters(['contentKitFilters']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    anyFilter() {
      return _.get(this.contentKitFilters, 'search', '').length > 2
        || _.get(this.contentKitFilters, 'selectedTypes', []).length > 0
        || _.get(this.contentKitFilters, 'selectedDimensions', []).length > 0
        || _.get(this.contentKitFilters, 'selectedPlatforms', []).length > 0;
      // || _.get(this.contentKitFilters, 'favoriteItems')
    },
    kitView() {
      return _.includes(['Content Kit', 'Client Content Kit', 'Business'], this.$route.name);
    },
    canUploadAssets() {
      return this.canUploadContentKits && this.currentContentKit.id;
    },
    isProjectActive() {
      return this.currentProject.status === '1';
    },
    contentKitId() {
      return this.$route.params.contentKitId || this.$route.query.contentKitId;
    },
  },
  data() {
    return {
      filterItems: [{ text: 'Active', value: '1' }, { text: 'Archived', value: '2' }],
      contentKitType: 1,
      types: [{ name: 'Photo', id: '1' }, { name: 'Video', id: '2' }, { name: 'Document', id: '3' }],
      sortBy: 'File Name',
      sortByItems: [{ text: 'File Name', value: 'name' }, { text: 'Most Recent', value: 'recent' }],
      mobileFilters: false,
      mobileSelectedFilters: {
        sortBy: 'recent',
        selectedTypes: [],
        selectedDimensions: [],
        selectedPlatforms: [],
      },
      canUploadContentKits: false,
    };
  },
  methods: {
    ...mapActions('contentKit', ['getDimensions', 'getSocialTags', 'checkUploadContentKit']),
    ...mapActions(['setFilters', 'resetFilters', 'setUploadingItems']),

    toggleFavorites() {
      this.contentKitFilters.favoriteItems = !this.contentKitFilters.favoriteItems;
      this.filtersUpdated();
    },
    filtersUpdated() {
      EventBus.$emit('filters-updated');
      this.setFilters(this.contentKitFilters);
    },
    uploadContentKits(files) {
      if (files && files.length) {
        const { contentKitId } = this;
        _.forEach(files, (file) => {
          const filedata = file;
          const { CancelToken } = axios;
          const source = CancelToken.source();
          filedata.cancelSource = source;
          this.setUploadingItems(
            Object.assign({}, { file: filedata }, { contentKitId, completed: false, error: null }),
          );
        });
      }
    },
    activeFilterChange(val) {
      EventBus.$emit('active-filter-updated', val);
    },
    applyFilters() {
      this.setFilters(Object.assign(this.contentKitFilters, this.mobileSelectedFilters));
      this.mobileFilters = false;
    },
    closeFilters() {
      this.mobileSelectedFilters = { ...this.contentKitFilters };
      this.mobileFilters = false;
    },
    resetMobileFilters() {
      this.mobileSelectedFilters = {
        sortBy: 'name',
        selectedTypes: [],
        selectedDimensions: [],
        selectedPlatforms: [],
      };
    },
    clearFilters() {
      this.resetFilters({ favoriteItems: this.contentKitFilters.favoriteItems });
    },
  },
  async mounted() {
    this.getDimensions();
    this.getSocialTags();
    const projectId = this.projectId || this.currentProject.id;
    if (projectId) {
      this.canUploadContentKits = await this.checkUploadContentKit({
        projectId,
      });
    }
  },
};
</script>

<style scoped lang="scss">
  .filters, ::v-deep .v-input, ::v-deep .v-label {
    color: black;
    font-family: $fontFamily1;
    font-size: 13px;
    font-weight: 500;
  }
  ::v-deep .theme--light.v-select .v-select__selection--comma {
    color: black;
  }
  .selected {
    color: $primary3;
  }
  .search-input {
    width: 205px;
  }
  ::v-deep .input-field {
    height: 40px;
  }
  ::v-deep .v-input__slot {
    padding-right: 0 !important;
    padding-left: 8px !important;
  }
  ::v-deep .search-input .v-input__control .v-input__slot {
    border: 1px solid $border2;
    padding-right: 0;
  }
  .type-filter {
    width: 64px;
  }
  .dimension-filter {
    width: 98px;
  }
  .platform-filter {
    width: 87px;
  }
  .sort-label {
    font-size: 13px;
    margin-top: 10px;
  }
  .clear-filter {
    ::v-deep .v-btn__content {
      font-size: 13px;
    }
  }
  .sort-items {
    max-width: 100px;
    .sort-dropdown {
      ::v-deep .v-input__slot, ::v-deep .v-input__append-inner{
        padding-left: 2px !important;
      }
      ::v-deep .v-select__selections input {
        display: none;
      }
      ::v-deep .v-select__selection--comma {
        overflow: auto;
        margin: 0;
        min-width: min-content;
      }
    }
  }

  .favorite-selected {
    background-color: $primary1 !important;
  }

  .files-filter {
    width: 110px;
    .v-select {
      border-radius: 6px;
    }
  }
  ::v-deep .upload-button {
    margin-top: 5px;
    width: 89px;
    height: 28px !important;
    border-radius: 19.5px;
    box-shadow: 0 2px 4px 0 rgba(127, 127, 127, 0.5);
    border: solid 2px $card-title;
    color: $card-title;
    label {
      display: flex;
      justify-content: center;
      cursor: pointer;
      background-color: white;
      border-radius: 19.5px;
    }
  }
</style>
<style lang="scss">
  .asset-filters-modal {
    background-color: white;
    .heading {
      background: white;
      position: fixed;
      z-index: 5;
      font-size: 16px;
      color: black;
      font-weight: 600;
    }
    .filters-list {
      overflow: hidden;
      position: absolute;
      padding: 50px 0;
      .v-input__slot {
        padding-left: 0 !important;
      }
      .input-field-label {
        margin-bottom: 10px;
      }
      .sort-title {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
        font-family: $fontFamily1;
      }
    }
    .actions {
      bottom: 0;
      position: fixed;
      background: white;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
   .search-input {
     .v-text-field input {
       font-size: 16px;
      }
    }
    .search-padding {
      padding-left: 4px;
    }
    .search-input {
    width: 170px !important;
    }
  }
</style>

<template >
  <div class="content-kit-file-preview px-2 pb-2 h-100">
    <div :class="isCreative ? '' : 'header'">
      <v-row class="my-0 heading px-5">
        <v-col class="d-flex align-center pb-0" cols="11">
          <div class="text-capitalize file-name notranslate"> {{item.name}} </div>
          <v-btn text class="pa-0 pl-2 primary--text btn-width"
            @click="$emit('download')" color="info">
            <img src="@/assets/svg/theme/cloud-computing.svg" class="mx-2 mt-1" width=16 height=16/>
            <span v-if="$vuetify.breakpoint.smAndUp">Download</span>
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-end pb-0 pl-0" cols="1">
          <v-icon color="darken-1" class="close" @click="closeForm">mdi-close</v-icon>
        </v-col>
      </v-row>
    </div>
    <div :class="isCreative ? 'item-body py-5' : 'item-body content-body py-0'">
      <div v-if="$vuetify.breakpoint.smAndUp">
        <v-btn
        icon
        x-large
        class="previous navigate-btn"
        @click="$emit('onNavigate', prevItemIndex)"
        :disabled="prevItemIndex < 0"
      >
        <v-icon class="left"> mdi-chevron-left </v-icon>
      </v-btn>
      <v-btn
        icon
        x-large
        class="next navigate-btn"
        @click="$emit('onNavigate', nextItemIndex)"
        :disabled="nextItemIndex < 0"
      >
        <v-icon class="right"> mdi-chevron-right </v-icon>
      </v-btn>
      </div>
      <v-row class="ma-0 d-flex">
        <!-- <v-col :cols="item.fileType !== '1' || isLandscape ? 12 : 6"
          class="py-0 text-center"> -->
        <v-col cols="12" lg="6" class="pa-0 item-image">
          <v-card class="d-flex align-center justify-center h-100" :key="item.id">
            <div v-if="$vuetify.breakpoint.xsOnly">
              <v-btn
                icon
                x-large
                class="previous navigation-btn"
                @click="$emit('onNavigate', prevItemIndex)"
                :disabled="prevItemIndex < 0"
              >
              <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn
                icon
                x-large
                class="next navigation-btn"
                @click="$emit('onNavigate', nextItemIndex)"
                :disabled="nextItemIndex < 0"
              >
              <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
            <div class="content-kit-favourties">
              <template v-if="customer">
                <v-icon class="favourtie"
                  color="primary"
                  @click="togglefavoriteIcon(item.id)"
                >
                  {{ item.favorite ? 'mdi-heart' : 'mdi-heart-outline' }}
                </v-icon>
              </template>
              <template v-else-if="item.favorite">
                <v-icon color="primary"> mdi-heart </v-icon>
              </template>
            </div>
              <template v-if="item.fileType == '1'">
                <template v-if="validImage">
                  <v-img contain
                    :key="item.file"
                    :src="getOptimizedS3ImageURL(item.file,{ width: 600, height: 600 })"
                    :style="$vuetify.breakpoint.mdAndDown ? mobileImageStyles : imageStyles"
                    :lazy-src="item.smallImage"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5">

                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </template>
                <template v-else>
                  <img contain
                    class="noimage-height"
                    :key="item.file"
                    :src="defaultImage"
                    alt="no-image"
                    :style="$vuetify.breakpoint.mdAndDown ? mobileImageStyles : imageStyles"
                  />
                </template>
              </template>
              <template v-else-if="item.fileType == '2'">
              <!-- <template v-if="fileFormat === 'mp4'"> -->
                <video-player
                  :options="videoOptions"
                  class="video-player"
                />
              <!-- </template> -->
              <!-- <template v-else>
                  <img contain
                    class="noimage-height"
                    :key="item.file"
                    :src="defaultImage"
                    alt="no-image"
                    :style="$vuetify.breakpoint.mdAndDown ? mobileImageStyles : imageStyles"
                  />
                </template> -->
              </template>
              <template v-else>
                <div class="pdf-loading" v-if="pdfLoading">
                  <v-progress-circular indeterminate color="grey"/>
                </div>
                <template v-if="fileFormat === 'pdf'">
                   <div class="pdf-container">
                    <pdf
                      class="pdf-page-container"
                      v-for="i in numPages"
                      :key="i"
                      :src="src"
                      :page="i"
                      style="height: 450px;"
                      type="application/pdf">
                    </pdf>
                  </div>
                </template>
                <template v-else>
                    <iframe class="contentkit-pdf"
                      :src="`https://view.officeapps.live.com/op/embed.aspx?src=${item.file}`"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      role="document"
                      width="100%"
                      height="450px"
                      :key="'contentkit-iframe-'+item.file"
                      frameborder="0"
                    >
                    </iframe>
                </template>
              </template>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6"
          class="item-caption pl-8 pt-0">
          <template>
            <v-row class="mb-3" v-if="item.fileType == '2' && fileFormat === 'mp4' && !customer">
              <label class="text-left input-field-label mr-3"> Poster </label>
              <Upload
                v-model="file"
                mode="justImage"
                @change="posterUpdate"
                :acceptedFiles="'.jpg,.jpeg,.png,.gif'"
              >
                <template v-slot:default="{filePreview}">
                  <div class="d-flex ma-auto profile-image-container"
                    :class="'border-none'"
                  >
                    <v-img
                      class="profile-image"
                      lazy-src="@/assets/svg/theme/profile.svg"
                      :src="image(filePreview)"
                      alt="Profile"
                    />
                    <div class="profile-image-loading" v-if="imageLoading">
                        <v-progress-circular indeterminate color="grey"/>
                    </div>
                    <div class="profile-image-overlay">
                        <img src="@/assets/svg/camera_event.svg" />
                        <span>Update</span>
                    </div>
                  </div>
                </template>
              </Upload>
            </v-row>
            <v-row class="pb-2">
              <v-col :cols="3" class="pa-0">
                <label class="text-left input-field-label"> Dimensions </label>
                <CustomComboBox
                  solo
                  dense
                  class="dimension-width"
                  :items="dimensions"
                  item-text="name"
                  item-value="id"
                  return-object
                  name="dimensions"
                  hide-details
                  :value="item.dimension"
                  :mode="editTags"
                  :notClearable="true"
                  @change="updateDimension"
                >
                  <span class="content">{{item.dimension && item.dimension.name}} </span>
                </CustomComboBox>
              </v-col>
            </v-row>
            <v-row class="py-2">
              <v-col class='pa-0'>
                <label class="text-left input-field-label"> Suggested Platforms </label>
                <CustomComboBox
                  solo
                  dense
                  multiple
                  :items="socialTags"
                  item-text="name"
                  return-object
                  name="skillsets"
                  hide-details
                  :mode="editTags"
                  v-model="item.socialTags"
                  class="notranslate"
                >
                  <span class="content">{{socialTagNames}} </span>
                </CustomComboBox>
              </v-col>
            </v-row>
          </template>
          <v-row class="py-2" v-if="item.captionNeeded">
            <v-col class="pa-0">
              <label class="text-left input-field-label">
                Caption
                <v-btn color="primary" class="ml-2"
                  x-small
                  icon
                  v-clipboard:copy="item.caption"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  :disabled="!item.caption"
                >
                  <v-icon dark> mdi-content-copy </v-icon>
                </v-btn>
              </label>
              <base-text-area-field
                :disabled="customer"
                :rows="3"
                name="caption"
                v-model.trim="item.caption"
                :mode="amIAssignedTOProject ? 'edit' : 'normal'"
                class="caption-field"
                hide-details
                dense
                solo
              >
                <span class="content">{{item.caption}}</span>
              </base-text-area-field>
            </v-col>
          </v-row>
          <template>
            <v-row class="py-2">
              <v-col class='pa-0'>
                <label class="text-left input-field-label"> Creatives </label>
                <template v-if="canEditTags">
                  <v-select
                    :items="creatives"
                    item-value="id"
                    item-text="name"
                    multiple=""
                    solo
                    flat
                    v-model="userIds"
                    hide-details=""
                    class="creatives-selection notranslate"
                    :menu-props="{'content-class' : 'creatives-dropdown notranslate'}"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small color="#e5e7fa" class="creative-chip" label>
                        <span class="notranslate">{{ item.name }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </template>
                <template v-else>
                  <div class="content notranslate"> {{itemCreativeNames}} </div>
                </template>
              </v-col>
            </v-row>
            <v-row class="py-2">
              <v-col class="pa-0">
                <label class="text-left input-field-label"> Notes </label>
                <base-text-area-field
                  :disabled="customer"
                  :rows="3"
                  name="Notes"
                  v-model.trim="item.notes"
                  class="notes-field"
                  hide-details
                  solo
                  dense
                  :mode="editTags"
                >
                  <span class="content" v-linkified :key="item.id"> {{item.notes}} </span>
                </base-text-area-field>
              </v-col>
            </v-row>
            <v-row class="py-2">
              <v-col class="pa-0" cols=4 v-if="this.currentKitProjectDeliverables.length >= 1">
                <div class="align">
                <label class="text-left input-field-label w-100"> Deliverable </label>
                  <v-select v-if="canEditTags"
                    label="Select Deliverable"
                    :items="currentKitProjectDeliverables"
                    :item-text="text"
                    item-value="id"
                    class="mb-n4 notranslate"
                    solo
                    flat
                    v-model="selectedDeliverable"
                    :menu-props="{'content-class' : 'notranslate'}"
                  >
                  </v-select>
                  <span class="content notranslate" v-else>
                    {{ selectedDeliverable
                    ? text(selectedDeliverable)
                    : ''}}
                  </span>
                </div>
              </v-col>
              <v-col v-else class="pl-1">
                No deliverables available.
              </v-col>
            </v-row>
            <v-row class="flex-column">
              <v-col class="pa-0 pl-1 mobile-switch"
              :class="roleChecking || customer ? 'pt-3' : ''">
                <div class="d-flex align-center">
                  <v-switch
                    inset
                    dense
                    hide-details
                    :light=true
                    v-model="item.isPrivate"
                    :disabled="roleChecking"
                  >
                  </v-switch>
                  <span> Private </span>
                </div>
              </v-col>
              <span class="hint pt-2" v-if="!roleChecking">
                * Making this file as private will block
                the creative from adding it to their Blended Sense Portfolio
              </span>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </div>
    <div :class="isCreative ? '' : 'footer'">
    <div class="mx-5"> <v-divider > </v-divider> </div>
    <div class="item-actions pa-5" v-if="canUpdate">
      <v-row>
        <v-col cols="12" class="d-flex justify-end py-0">
          <v-btn
            color
            type="submit"
            class="pa-0 info ml-3"
            @click="updateAsset(false)"
          >
            Save
          </v-btn>
          <v-btn
            color
            type="submit"
            class="px-2 info ml-3"
            @click="updateAsset"
          >
            Save & Close
          </v-btn>
          <v-btn
            @click="closeForm"
            class="py-0 ml-3 cancel"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import linkify from 'vue-linkify';
import pdf from 'vue-pdf';
import _ from 'lodash';
import BaseTextAreaField from '@/components/common/BaseTextAreaField';
import VideoPlayer from '@/components/common/VideoPlayer';
import CustomComboBox from '@/components/common/CustomComboBox';
import Upload from '@/components/common/Upload';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  /* eslint-disable global-require */
  components: {
    BaseTextAreaField,
    CustomComboBox,
    Upload,
    VideoPlayer,
    pdf,
  },
  directives: {
    linkified: linkify,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    userRole: {
      type: String,
      required: true,
    },
    prevItemIndex: {
      type: Number,
      default: 0,
    },
    nextItemIndex: {
      type: Number,
      default: 0,
    },
    src: {
      type: Object,
      required: true,
    },
    numPages: {
      type: Number,
      default: 0,
    },
    pdfLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    item(newVal) {
      this.userIds = _.map(newVal.users, 'id');
      this.getDeliverable(newVal);
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['producersTeam', 'creativeTeam', 'executiveTeam', 'currentProject']),
    ...mapGetters('contentKit', ['dimensions', 'socialTags', 'currentKitProjectDeliverables']),
    customer() {
      return ['client', 'member'].includes(this.userRole);
    },
    amIAssignedTOProject() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return assigned;
    },
    image() {
      return (filePreview) => {
        const profilePic = _.get(this.item, 'poster');
        if (filePreview) {
          return filePreview;
        }
        if (profilePic) {
          // this.setBorderVisibility();
          return profilePic;
        }
        return require('@/assets/svg/users.svg');
      };
    },
    socialTagNames() {
      return _.compact(_.map(this.item.socialTags, (tag) => tag.name.trim())).join(', ');
    },
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: this.item.file,
            type: 'video/mp4',
          },
        ],
        poster: this.poster,
      };
    },
    poster() {
      return this.item.poster;
    },
    isCreative() {
      return this.userRole === 'creative';
    },
    isExecutive() {
      return this.userRole === 'executive';
    },
    isProducer() {
      return this.userRole === 'producer';
    },
    roleChecking() {
      if (this.isExecutive || this.isProducer || this.customer) {
        return false;
      }
      return true;
    },
    canUpdate() {
      if (this.isCreative) {
        return this.item.captionNeeded;
      }
      if (this.customer) {
        return true;
      }
      return this.amIAssignedTOProject;
    },
    canEditTags() {
      return this.amIAssignedTOProject && (this.isExecutive || this.isProducer);
    },
    editTags() {
      return this.canEditTags ? 'edit' : 'normal';
    },
    isLandscape() {
      return this.width >= this.height;
    },
    fileFormat() {
      return _.split(this.item.fileFormat, '/')[1];
    },
    validImage() {
      return !_.includes(['ai', 'psd'], this.fileFormat);
    },
    defaultImage() {
      if (this.fileFormat === 'ai') {
        return require('@/assets/svg/theme/ai.svg');
      }
      if (this.fileFormat === 'psd') {
        return require('@/assets/svg/theme/psd.svg');
      }
      if (this.fileFormat === 'x-subrip') {
        return require('@/assets/svg/theme/srt.svg');
      }
      return require('@/assets/svg/theme/project-banner-default.svg');
    },
    imageStyles() {
      return this.isLandscape ? 'max-width: 100%; max-height: 600px;' : 'max-height: 600px;';
    },
    mobileImageStyles() {
      return this.isLandscape ? 'max-width: 100%;' : 'max-height: 450px;';
    },
    creatives() {
      return _.map(this.projectTeam, (creative) => ({ id: creative.id, name: `${creative.firstName} ${creative.lastName}` }));
    },
    projectTeam() {
      return [
        ..._.chain(this.producersTeam)
          .filter((producer) => producer.accepted === '1')
          .map((producer) => producer.user)
          .value(),
        ..._.chain(this.creativeTeam)
          .filter((creative) => creative.accepted === '1')
          .map((creative) => creative.user)
          .value(),
        ..._.chain(this.executiveTeam)
          .filter((executive) => executive.accepted === '1')
          .map((executive) => executive.user)
          .value(),
      ];
    },
    itemCreativeNames() {
      return _.map(this.item.users, (c) => `${c.firstName} ${c.lastName}`).join(', ');
    },
    // notEdited() {
    //   const attrs = ['dimension', 'socialTags', 'caption', 'notes', 'userIds'];
    //   return _.isEqual(_.pick(this.itemBeforeEdit, attrs), _.pick(this.item, attrs));
    // },
  },
  data() {
    return {
      selectedDeliverable: '',
      userIds: _.map(this.item.users, 'id'),
      itemBeforeEdit: {},
      width: 200,
      height: 100,
      imageLoading: false,
      file: '',
    };
  },
  mounted() {
    this.getDeliverable(this.item);
  },
  methods: {
    ...mapActions(['setNotification']),
    ...mapActions('contentKit', ['updateContentKitFile', 'updateFavoriteContentKits', 'unfavoriteContentKits', 'uploadPoster']),
    getOptimizedS3ImageURL,
    getDeliverable(item) {
      this.selectedDeliverable = '';
      _.map(this.currentKitProjectDeliverables, (deliverable) => {
        if (deliverable.deliverableId === item.deliverableId) {
          this.selectedDeliverable = deliverable;
        }
      });
    },
    text(item) {
      if (item.deliverable.category) {
        return `${item.deliverable.category.name} (${item.deliverable.name})`;
      }
      return item.deliverable.name;
    },
    updateAsset(closePreview = true) {
      const projectDeliverableId = this.selectedDeliverable.id
        ? this.selectedDeliverable.id : this.selectedDeliverable;
      this.updateContentKitFile({
        contentKitId: this.item.contentKitId,
        payload: Object.assign(this.item,
          {
            contentKitFileId: this.item.id,
            userIds: this.userIds,
            projectDeliverableId,
          }),
      });
      if (closePreview) { this.$emit('close-modal'); }
    },
    onCopy() {
      this.setNotification({
        message: 'Caption copied to clipboard.',
        type: 'success',
      }, { root: true });
    },
    onError(e) {
      this.setNotification({
        message: e.message,
        type: 'error',
      }, { root: true });
    },
    updateDimension(value) {
      if (typeof value === 'string') {
        this.item.dimension = { name: value };
        this.getPlatforms(value);
      } else {
        this.item.dimension = value;
        this.getPlatforms(value.name);
      }
    },
    closeForm() {
      this.item = Object.assign(this.item, this.itemBeforeEdit);
      this.$emit('close-modal');
    },
    calculateDimensions() {
      [this.width, this.height] = this.item.dimensions.split('X').map((x) => +x);
    },
    async posterUpdate(file) {
      this.imageLoading = true;
      const result = await this.uploadPoster({ fileId: this.item.id, file });
      if (result.success) {
        this.item = Object.assign(this.item, { poster: result.contentKitFile.poster });
      }
      this.imageLoading = false;
    },
    async togglefavoriteIcon(id) {
      const result = this.item.favorite
        ? await this.unfavoriteContentKits(id) : await this.updateFavoriteContentKits(id);
      if (result.success) {
        this.item = Object.assign(this.item, { favorite: !this.item.favorite });
      }
    },
    getPlatforms(dimension) {
      switch (dimension) {
        case '1 x 1':
        case '4 x 5':
          this.item.socialTags = _.filter(this.socialTags, (tag) => _.includes(['Instagram', 'Facebook'], tag.name));
          break;
        case '9 x 16':
          this.item.socialTags = _.filter(this.socialTags, (tag) => _.includes(['Instagram Stories', 'Facebook Stories', 'Tik Tok'], tag.name));
          break;
        case '16 x 9':
          this.item.socialTags = _.filter(this.socialTags, (tag) => _.includes(['Facebook', 'LinkedIn', 'Instagram TV', 'YouTube', 'Website'], tag.name));
          break;
        case '4 x 6':
        case '6 x 4':
          this.item.socialTags = _.filter(this.socialTags, (tag) => _.includes(['Facebook'], tag.name));
          break;
        default:
          this.item.socialTags = [];
      }
    },
  },
  created() {
    this.itemBeforeEdit = Object.assign({}, this.item);
  },

  beforeMount() {
    if (this.item.fileType === '1') {
      this.calculateDimensions();
    }
  },
  beforeUpdate() {
    if (this.item.fileType === '1') {
      this.calculateDimensions();
    }
  },
};
</script>

<style scoped lang="scss">
  .content-kit-file-preview {
    background: $neutral3;
    font-family: $fontFamily1;
    font-size: 14px;
    overflow-y: auto;
    overflow-x: hidden;
    .file-name {
      font-size: 18px;
      font-weight: bold;
      word-break: break-all;
    }
  }
  .content-body {
    margin-top: 70px !important;
    top: 0;
    height: calc(100vh - 170px) !important;
  }
  .item-body {
    margin-top: 40px;
    top: 0;
    margin: 0 auto;
    overflow: auto;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    .navigate-btn {
      padding: 0;
      position: absolute;
      top: 220px;
      &.previous {
        left: 0;
      }
      &.next {
        right: 0;
      }
    }
    .dimension-width{
      width:120px;
    }
    .content {
      color: $neutral7;
    }
    .item-image {
      background: $neutral5;
      line-height: 0;
    }
    img {
      max-height: 480px;
    }
    .video-player {
      margin: 0 auto;
      height: 500px;
    }
    .combo-box {
      margin-bottom: 0px;
    }
    .v-chip.v-size--small {
      font-size: 13px;
    }
    .creative-chip {
      color: $primary2;
    }
    .creatives-selection {
      ::v-deep .v-input__control {
        min-height: 36px;
      }
    }
  }
  .hint {
    font-style: italic;
  }
  .input-field-label {
    font-weight: bold;
  }
  ::v-deep .v-input {
    height: auto !important;
    font-size: 14px;
  }
  ::v-deep .v-input__slot {
    box-shadow: none !important;
  }
  ::v-deep .theme--light.v-text-field--outlined > .v-input__control {
    border-radius: 10px;
    > .v-input__slot {
      background-color: white;
      padding: 0 16px;
      .v-label--active {
        top: 30px;
      }
      legend {
        width: 0 !important;
      }
      textarea {
        margin-top: 10px;
      }
    }
  }
  ::v-deep .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls
  .v-radio > .v-label {
    font-size: 14px;
    padding-bottom: 4px;
    font-family: $fontFamily1;
    color: rgba(0, 0, 0, 0.87);
  }
  ::v-deep .theme--light.v-text-field--outlined > .v-input__control > .v-input__slot:before {
    background: white;
    border: solid 1px #9e9e9e;
  }

  .video-player {
    width: 100%;
  }

  .item-actions {
    .v-btn {
      border-radius: 6px;
      ::v-deep .v-btn__content {
        font-size: 16px;
        font-family: $fontFamily1;
        font-weight: 500;
        letter-spacing: normal;
      }
    }
  }
  .content-kit-favourties {
    position: absolute;
    bottom: 30px;
    right: 10px;
    z-index: 2;
  }
  .star-outline {
    -webkit-text-stroke: 1.8px #F49C14;
    color: transparent!important;
    font-size: 20px !important;
  }
  .star-fill {
    color: #F4C40C;
    font-size: 24px;
  }
  .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
    border-radius: 0px !important;
  }
  .pdf-container{
    overflow-y: auto;
    height: 450px;
    .pdf-page-container{
      width:100%;
      padding-bottom: 10px;
    }
  }

  .profile-image-container {
    width: 96px;
    height: 96px;
    border: 2px solid #565682;
    border-radius: 24px;
    cursor: pointer;
    position: relative;
  }
  .pdf-loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .profile-image {
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
    .profile-image-loading {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .profile-image-overlay {
        position: absolute;
        height: 55px;
        bottom: 0;
        background: $neutral5;
        display: none;
        width: 100%;
        border-radius: 0 0 22px 22px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .profile-image-container:hover .profile-image-overlay {
       display: flex;
    }
    .noimage-height{
      height:300px;
      padding: 50px 0;
    }
  .header {
    position: fixed;
    width: 100%;
    top:0px;
    left:0px;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center
  }
  .align {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-height: 766px) {
    .item-body {
      img {
        max-height: 550px !important;
      }
    }
  }

  @media screen and (max-height: 700px) {
    .item-body {
      embed, img {
        max-height: 450px !important;
      }
      .video-player {
        height: 450px !important
      }
    }
  }

  @media only screen and (min-width: 600.1px) and (max-width: 1264px)  {
    .item-body {
      min-height: 220px;
      embed, img {
        max-height: 400px !important;
      }
      .video-player {
        height: 430px !important
      }
    }
    .item-image {
      min-height: 250px;
    }
    .item-caption {
      padding-left: 15px !important;
      padding-top: 15px !important;
    }
    .contentkit-pdf {
      height: 400px;
    }
    .noimage-height{
      height:200px;
      padding: 20px 0;
    }
  }

  @media screen and (max-width: 600px) {
    .btn-width {
      min-width: 0px !important;
    }
    .item-body {
      padding-left: 20px !important;
      padding-right: 20px !important;
      .navigation-btn {
        padding: 0;
        position: absolute;
        z-index: 2;
        height: 32px;
        width: 32px;
        background: $neutral5;
        &.previous {
          left: 0;
        }
        &.next {
          right: 0;
        }
      }
      .video-player {
        height: 250px !important;
      }
      .noimage-height{
        height: 220px;
        padding: 40px 0;
      }
    }
    .item-image {
      min-height: 210px;
    }
    .item-caption {
      padding-left: 15px !important;
      padding-top: 15px !important;
    }
    .contentkit-pdf {
      height: 300px;
    }
  }

@media screen and (max-width: 960px) and (orientation: landscape) {
    .navigate-btn {
      top: 135px !important;
    }
}

@media (orientation: portrait) {
   .navigate-btn {
      top: 180px !important;
    }
}
</style>

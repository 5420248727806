<!-- eslint-disable max-len -->
<template >
  <div class="selected-actions" :class="{'xs-screen':$vuetify.breakpoint.xs, 'small-screen':$vuetify.breakpoint.smOnly, 'medium-screen':$vuetify.breakpoint.mdAndUp}">
    <!-- <v-row v-if="this.$route.path === '/library/all_files'">
      <v-col class="d-flex justify-end"
       v-if="this.$route.path === '/library/all_files'">
        <v-btn text class="px-2 primary--text" @click="$emit('download')" color="info">
          <img src="@/assets/svg/theme/cloud-computing.svg" class="ml-2 mr-2" width=16 height=16/>
          Download
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row class="flex-nowrap align-center">
      <v-col class="d-flex align-center" :class="{'mobile-screen': $vuetify.breakpoint.xsOnly}">
        <v-btn icon @click="$emit('removeSelection')">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        {{count}} Selected
      </v-col>
      <v-col cols=12 sm=7 class="justify-end" :class="{'py-0': $vuetify.breakpoint.xsOnly,'d-none': $vuetify.breakpoint.mdAndDown, 'd-flex': $vuetify.breakpoint.lgAndUp}">
        <v-btn text class="px-2 primary--text" @click="$emit('selectAll')" color="info" v-if="this.$route.name !== 'Assets'">
          Select all
        </v-btn>
        <v-btn text class="px-2 primary--text" @click="$emit('download')" color="info">
          <img src="@/assets/svg/theme/cloud-computing.svg" class="ml-2 mr-2" width=16 height=16/>
          Download
        </v-btn>
        <v-btn text class="px-2 primary--text" @click="$emit('archive')" color="info"
          v-if="showArchive"
        >
          <img src="@/assets/svg/theme/folder.svg" class="ml-2 mr-2" width=16 height=16/> Archive
        </v-btn>
        <template v-if="canUpdate">
          <v-btn text class="px-2 primary--text" @click="$emit('assign')" color="info"
            v-if="activeFilter && !showAssigned">
            <img src="@/assets/svg/theme/checkmark.svg" class="ml-2 mr-2" width=20 height=20/> Assign
          </v-btn>
          <v-btn text class="px-2 primary--text" @click="$emit('assignToDeliverable')" color="info"
            v-if="activeFilter && !showAssigned">
            <img
            src="@/assets/svg/theme/tag.svg"
            class="ml-2 mr-2" width=20 height=20/> Assign Deliverable
          </v-btn>
          <v-btn text class="px-2 primary--text" @click="$emit('bulkEdit')" color="info"
            v-if="activeFilter">
            <img
            src="@/assets/svg/theme/assign.svg"
            class="ml-2 mr-2" width=20 height=20/>Bulk Edit
          </v-btn>
          <v-btn text class="px-2 primary--text" @click="$emit('move')" color="info">
            <img src="@/assets/svg/theme/move.svg"
             class="ml-2 mr-2" width=16 height=16/> Move
          </v-btn>
          <v-btn text class="px-2 primary--text release-btn"
            @click="$emit('release')" color="info"
            v-if="activeContentKit && activeFilter && !showReleased
            && !currentContentKit.isDraft">
            <img src="@/assets/svg/theme/release.svg" class="ml-2 mr-2" width=16 height=16/> Release
          </v-btn>
        </template>
      </v-col>
      <v-col class="justify-end" :class="{'d-none': $vuetify.breakpoint.lgAndUp, 'd-flex': $vuetify.breakpoint.mdAndDown}">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn
              v-bind="attrs"
              v-on="on" text class="px-2 primary--text">
                <img src="@/assets/svg/theme/move.svg" class="ml-2 mr-2" width=16 height=16/>
            </v-btn> -->
            <v-btn text class="px-2 primary--text" color="info"
              v-bind="attrs"
              v-on="on">
              <svg width="32" height="32" fill="black" viewBox="0 0 24 24">
                <path fill="black" d="M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"/>
                <path fill="black" d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"/>
                <path fill="black" d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"/>
              </svg>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn text class="px-2 primary--text" @click="$emit('selectAll')" color="info" v-if="this.$route.name !== 'Assets'">
                <span>Select all</span>
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn text class="px-2 primary--text" @click="$emit('download')" color="info">
                <img src="@/assets/svg/theme/cloud-computing.svg" class="ml-2 mr-2" width=16 height=16/>
                Download
            </v-btn>
            </v-list-item>
            <v-list-item v-if="showArchive">
              <v-btn text class="px-2 primary--text" @click="$emit('archive')" color="info">
                <img src="@/assets/svg/theme/folder.svg" class="ml-2 mr-2" width=16 height=16/> Archive
              </v-btn>
            </v-list-item>
            <template v-if="canUpdate">
              <v-list-item  v-if="activeFilter && !showAssigned">
                <v-btn text class="px-2 primary--text" @click="$emit('assign')" color="info">
                  <img src="@/assets/svg/theme/checkmark.svg" class="ml-2 mr-2" width=20 height=20/> Assign
                </v-btn>
              </v-list-item>
              <v-list-item
                v-if="activeFilter && !showAssigned"
              >
                <v-btn text class="px-2 primary--text" @click="$emit('assignToDeliverable')" color="info">
                  <img src="@/assets/svg/theme/tag.svg" class="ml-2 mr-2" width=20 height=20/> Assign Deliverable
                </v-btn>
              </v-list-item>
              <v-list-item
                v-if="activeFilter"
              >
                <v-btn text class="px-2 primary--text" @click="$emit('bulkEdit')" color="info">
                  <img src="@/assets/svg/theme/assign.svg" class="ml-2 mr-2" width=20 height=20/>Bulk Edit
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn text class="px-2 primary--text" @click="$emit('move')" color="info">
                  <img src="@/assets/svg/theme/move.svg"
                  class="ml-2 mr-2" width=16 height=16/> Move
                </v-btn>
              </v-list-item>
              <v-list-item
                v-if="activeContentKit && activeFilter && !showReleased
                  && !currentContentKit.isDraft"
              >
                <v-btn text class="px-2 primary--text release-btn"
                  @click="$emit('release')" color="info"
                  >
                  <img src="@/assets/svg/theme/release.svg" class="ml-2 mr-2" width=16 height=16/> Release
                </v-btn>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    showArchive: {
      type: Boolean,
      default: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    filterType: {
      type: Number,
      required: true,
    },
    activeContentKit: {
      type: Boolean,
      required: true,
    },
    showReleased: {
      type: Boolean,
      default: false,
    },
    showAssigned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('contentKit', ['currentContentKit']),
    activeFilter() {
      return this.filterType === 1;
    },
  },
};
</script>

<style lang="scss">
  .selected-actions {
    background: white;
    font-size: 16px;
    position: fixed;
    top: 0;
    z-index: 20;
    padding-top: 5px;
    display: flex;
    align-items: center;
    &.xs-screen{
      width: 100% !important;
      margin-top: 1.3em;
      left: 0 !important;
    }
    &.small-screen {
      margin-top: 0 !important;
      width: calc(100% - 56px);
      left: 56px;
    }
    &.medium-screen{
      margin-top: 0 !important;
      width: calc(100% - 320px);
    }
  }

  @media (max-width: 960px) {
    .selected-actions {
      left: 0;
      padding-right: 10px;
      width: 100%;
      .v-col {
        padding-top: 0px;
        padding-bottom: 0;
      }
      .v-btn {
        padding: 0 !important;
        font-size: 12px;
      }
    }
}

  @media (max-width: 600px) {
    .selected-actions {
      top:64px;
      height: 54px;
    }
    .mobile-screen {
    padding-bottom: 0px;
    padding-top: 0px;
    height: 20px;
    }
  }
</style>

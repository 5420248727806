<template>
  <v-form class="add-project-form font-family-2" @submit="submitForm">
    <v-card class="pa-1" flat>
      <v-card-title class="pb-5"> {{ title }} </v-card-title>
      <v-card-text class="text--primary">
        <base-input
          label="Content Kit Name *"
          name="contentKitName"
          v-model.trim="contentKit.name"
          :status="errors('name').length ? 'error': 'normal'"
          :error-messages="errors('name')"
          @input="$v.contentKit.name.$touch()"
          @blur="$v.contentKit.name.$touch()"
        />
        <div class="pt-2">
          <date-picker
            label="Date *"
            name="date"
            v-model="contentKit.date"
            :status="errors('date').length ? 'error': 'normal'"
            :error-messages="errors('date')"
            @input="$v.contentKit.date.$touch()"
            @blur="$v.contentKit.date.$touch()"
          />
        </div>
        <div class="pt-4" v-if="contentKit.id">
          <v-checkbox
            v-model="contentKit.status"
            dense
            class='multi-check ml-n1'
            off-icon="mdi-checkbox-blank-outline"
            on-icon="mdi-checkbox-marked"
            label="Completed"
            true-value="0"
            false-value="1"
          > </v-checkbox>
        </div>
        <div>
          <label class="text-left input-field-label"> Banner Image </label>
          <Upload
            id="upload-documents"
            class="upload-button"
            mode="justImage"
            v-model="contentKit.bannerImage"
            :acceptedFiles="'.jpg,.jpeg,.png,.svg,.gif'"
          >
            <template v-if="!contentKit.bannerImage">
              <span class="add-text h-100">Upload</span>
            </template>
          </Upload>
          <v-chip
            v-if="contentKit.bannerImage"
            close
            @click:close="deleteBannerImage()"
            color="#e5e7fa"
            class="agreement-file mt-1 notranslate"
            :close-icon="'mdi-close'"
          >
            {{ bannerImage }}
          </v-chip>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end px-3 py-3">
        <v-btn text @click="$emit('close-modal')" color="primaryGray1">Close</v-btn>
        <v-btn text color="info" type="submit" class="pa-0 ml-2 add-contentkit-button"
          :loading="loading"
        >
          {{ action }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import { url } from '@/helpers';
import BaseInput from '@/components/common/BaseInput';
import DatePicker from '@/components/common/DatePicker';
import Upload from '@/components/common/Upload';


export default {
  components: {
    BaseInput,
    DatePicker,
    Upload,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    contentKitObj: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    title() {
      if (this.contentKitObj.id) {
        return 'Edit Content Kit';
      }
      return 'Add New Content Kit';
    },
    action() {
      if (this.contentKitObj.id) {
        return 'UPDATE CONTENT KIT';
      }
      return 'ADD CONTENT KIT';
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.contentKit[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.contentKit.name.required) { errors.push('Please provide valid name'); }
            break;
          case 'date':
            if (!this.$v.contentKit.date.required) { errors.push('Please provide valid date'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    bannerImage() {
      if (_.isObject(this.contentKit.bannerImage)) {
        return this.contentKit.bannerImage.name;
      }
      return 'Banner Image';
    },
  },
  data() {
    return ({
      contentKit: {
        name: '',
        date: new Date().toISOString().substr(0, 10),
        projectId: this.projectId,
        bannerImage: null,
      },
      loading: false,
    });
  },
  methods: {
    ...mapActions('contentKit', ['addContentKit', 'updateContentKit']),
    async submitForm(e) {
      this.loading = true;
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const formData = new FormData();
        _.map(this.contentKit, (val, key) => {
          if (key === 'bannerImage' && !url(val)) {
            formData.append('bannerImage', val);
          } else if (val !== null) {
            formData.append(key, _.isObject(val) ? JSON.stringify(val) : val);
          }
        });
        let result;
        if (this.contentKit.id) {
          result = await this.updateContentKit({
            id: this.contentKit.id,
            payload: formData,
          });
        } else {
          result = await this.addContentKit(formData);
        }
        if (result.success) {
          this.$emit('close-modal');
        }
      }
      this.loading = false;
    },
    deleteBannerImage() {
      this.contentKit.bannerImage = '';
    },
  },
  validations: {
    contentKit: {
      name: { required },
      date: { required },
    },
  },
  mounted() {
    if (this.contentKitObj.id) {
      this.contentKit = { ...this.contentKitObj };
    }
  },
};
</script>

<style scoped lang="scss">
  .v-card {
    border-radius: 0 !important;
    .v-card__title {
      font-size: 24px;
      font-weight: 500;
      font-family: $fontFamily1;
    }
  }
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
  }
  .add-contentkit-button {
    padding-left: 14px !important;
  }
  .upload-button {
    font-family: $fontFamily1;
    font-size: 12px;
    letter-spacing: -0.01px;
    color: $primary3;
    span {
      cursor: pointer !important;
    }
  }
  ::v-deep .v-input {
    input:-webkit-autofill {
      -webkit-box-shadow:0 0 0 50px white inset;
    }
  }
  ::v-deep .v-label {
    font-family: $fontFamily1;
    font-size: 16px;
    color: $neutral1;
  }
</style>

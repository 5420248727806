<template>
    <div class="bg-white project-overview-container h-100">
    <div class="main-container h-100">
      <!-- <content-kits-header
      @new-content-kit="setModal(true)"
      @search-change="searchChange"
      :canAdd="canAccess"
    > </content-kits-header> -->
    <Loader v-if="loading" />
    <div v-else>
      <div v-if="reference !== 'business' || page !== 'org/business'">
        <v-select
          style="width:250px;"
          class="type-selection mb-n3 notranslate"
          dense
          solo
          flat
          :items="workspaceList"
          v-model="selectedWorkspaceId"
          append-icon="mdi-chevron-down"
          label="Select"
          v-if="!loading"
        ></v-select>
      </div>
      <ContentKitFilters
        :canAddKit="canAccess"
        :showSearch="true"
        @new-content-kit="addContentKit = true"
        :projectId="Number(selectedWorkspaceId)"
      > </ContentKitFilters>
      <template v-if="anyFilter">
        <Assets>
        </Assets>
      </template>
      <template v-else>
        <content-kit-list
          :parent="parent"
          :search="searchValue"
          :projectId="Number(selectedWorkspaceId)"
          :canUpdate="canAccess"
          @onEdit="editContentKit"
          @filters-change="filtersValue"
          @changeStep="(data) => {
            $emit('changeStep', data);
          }"
          :loading="() => loading=true"
          :loadDone="() => loading=false"
        >
        </content-kit-list>
      </template>
      <v-container
        fluid
        v-if="addContentKit"
      >
        <Modal
          persistent
          content-class="main-modal"
          :modal="addContentKit"
          width="600"
        >
          <add-content-kit
            @close-modal="closeForm"
            :projectId="selectedWorkspaceId"
            :contentKitObj="selectedKit"
          />
        </Modal>
      </v-container>
    </div>
  </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import Modal from '@/components/common/Modal';
import Assets from '@/views/Assets';
// import ContentKitsHeader from '../components/contentKits/ContentKitsHeader';
import Loader from '@/components/common/Loader';
import ContentKitList from '../components/contentKits/ContentKitList';
import AddContentKit from '../components/contentKits/AddContentKit';
import ContentKitFilters from '../components/contentKits/ContentKitFilters';

export default {
  name: 'ContentKits',
  props: {
    page: {
      type: String,
      default: '',
    },
    parent: {
      type: String,
      default: '',
    },
    reference: {
      type: String,
      default: '',
    },
  },
  components: {
    AddContentKit,
    Assets,
    ContentKitFilters,
    // ContentKitsHeader,
    ContentKitList,
    Modal,
    Loader,
  },
  computed: {
    ...mapGetters('project', ['currentProject']),
    ...mapGetters(['modal', 'contentKitFilters']),
    ...mapGetters('user', ['userDetails']),
    canAccess() {
      const currentWorkspace = this.workspaceList.find(
        (workspace) => parseInt(workspace.projectId, 10) === parseInt(this.selectedWorkspaceId, 10),
      );
      if (currentWorkspace) {
        return currentWorkspace.canAccess;
      }
      return false;
    },
    // amIAssignedToProject() {
    //   const team = _.get(this.currentProject, 'business.businessTeam', []);
    //   const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
    //   return assigned;
    // },
    // isProjectArchived() {
    //   return this.currentProject.status === '2';
    // },
    anyFilter() {
      return _.get(this.contentKitFilters, 'search', '').length > 2
        || _.get(this.contentKitFilters, 'selectedTypes', []).length > 0
        || _.get(this.contentKitFilters, 'selectedDimensions', []).length > 0
        || _.get(this.contentKitFilters, 'selectedPlatforms', []).length > 0
        || _.get(this.contentKitFilters, 'favoriteItems');
    },
  },
  data() {
    return ({
      status: 1,
      searchValue: '',
      addContentKit: false,
      selectedKit: {},
      filter: false,
      selectedWorkspaceId: null,
      selectedWorkspace: {},
      businessWorkspaces: [],
      workspaceList: [],
      loading: true,
    });
  },
  watch: {
    async selectedWorkspaceId(newVal) {
      if (newVal) {
        this.$emit('selectedProjectId', newVal);
        this.selectedWorkspace = _.find(this.businessWorkSpaces, ['id', newVal]);
        await this.getProject(newVal);
      }
    },
  },
  methods: {
    ...mapActions('project', ['getProject']),
    ...mapActions(['setModal', 'resetFilters']),
    ...mapActions('business', ['loadBusinessWorkspaces']),
    searchChange(value) {
      this.searchValue = value;
    },
    editContentKit(kit) {
      this.selectedKit = kit;
      this.addContentKit = true;
    },
    filtersValue(value) {
      if (value > 0) {
        this.filter = true;
      } else {
        this.filter = false;
      }
    },
    closeForm() {
      this.addContentKit = false;
      this.selectedKit = {};
    },
  },
  async mounted() {
    try {
      this.loading = true;
      // eslint-disable-next-line max-len
      let businessId = this.$route.query.businessId || this.$route.params.businessId;
      if (['organization'].includes(this.page)) {
        businessId = this.$route.params.organizationId;
      }
      if (['org/business'].includes(this.page)) {
        const id = this.$route.params.businessId || this.$route.query.businessId;
        businessId = id;
      }
      const { projects, entityType } = await this.loadBusinessWorkspaces({ businessId });
      this.businessWorkSpaces = projects;
      const workspaceList = _.map(projects, (project) => {
        let displayName = null;
        if (entityType === 'organization') {
          displayName = project.name;
        } else {
          displayName = project.business.name;
        }
        return {
          // eslint-disable-next-line
          text: `${displayName}`,
          value: project.id,
          projectId: project.id,
          canAccess: project.isAssigned,
        };
      });
      this.selectedWorkspaceId = _.get(workspaceList, '[0]').value;
      this.$emit('selectedProjectId', this.selectedWorkspaceId);
      this.selectedWorkspace = _.find(this.businessWorkSpaces, ['id', this.selectedWorkspaceId]);
      this.workspaceList = workspaceList;
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.resetFilters();
  },
};
</script>

<style scoped lang="scss">
 .content-wrapper {
   min-height: 50vh;
 }
  @media (max-width: 600px) {
    .content-wrapper {
      padding: 15px 15px 0;
      padding-top: 50px;
    }
  }
</style>

<template>
  <div class='card'>
    <div class="card-body" :id="'card-file-' + section + item.id">
      <v-hover v-slot:default="{ hover }">
      <div class="card-file d-flex justify-center align-center"
        :class="{ 'on-hover': hover}"
      >
        <div class="menu" :class="{mobile: $vuetify.breakpoint.xsOnly}">
          <v-menu auto :attach="'#card-file-' + section + item.id">
            <template v-slot:activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
                height="auto"
                width="auto"
                v-show="hover || device"
                class="menu-btn"
              >
                <v-icon dense>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text class="w-100" @click="toggleInfo">
                      <span class="pr-2 d-flex"> <Info /> </span> Info
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isProjectActive && canUpdate">
                <v-list-item-title>
                  <v-btn text class="w-100" @click="rename = 'edit'">
                    <v-icon small class="pr-2">mdi-square-edit-outline</v-icon> Rename
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-title>
                  <v-btn text class="w-100" @click="showPreview">
                    <v-icon small class="pr-2">mdi-eye</v-icon> Preview
                  </v-btn>
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item v-if="item.status != 2 && isProjectActive && canUpdate">
              <v-list-item-title>
                  <v-btn text class="w-100" @click="archiveFile">
                    <img
                      src="@/assets/svg/theme/archive.svg"
                      class="download-icon"
                      width=24
                      height=14
                    /> Archive
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn text class="w-100" @click="$emit('onDownload')">
                    <img
                      src="@/assets/svg/theme/download-small.svg"
                      class="download-icon"
                      width=24
                      height=14
                    />
                    Download
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isProjectActive && canUpdate">
                <v-list-item-title>
                  <v-btn text class="w-100" @click="deleteFile">
                    <v-icon class="delete-icon">mdi-delete-outline</v-icon>
                    Delete
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div
          class="deliver-status px-4 success white--text"
          v-if="item.released === true && !customer && !agency_customer"> DELIVERED
        </div>
        <div class="content-kit-favourties">
          <template v-if="customer || agency_customer">
            <v-icon
              color="primary"
              @click="togglefavoriteIcon(item.id)"
            >
              {{ item.favorite ? 'mdi-heart' : 'mdi-heart-outline' }}
            </v-icon>
          </template>
          <template v-else-if="item.favorite">
            <v-icon color="primary"> mdi-heart </v-icon>
          </template>
        </div>
        <div class="deliverable-icon">
          <template v-if="item.deliverableId">
            <v-tooltip
              bottom
            >
              <template v-slot:activator="{ on }">
                 <img v-on="on" src="@/assets/svg/theme/tag.svg" width=20 height=20/>
              </template>
              <span>
                {{deliverableName}}
              </span>
            </v-tooltip>
            <!-- <v-icon color="primary"> mdi-tag-outline </v-icon> -->
          </template>
        </div>
        <div v-if="item.fileType === '2' && ['mov', 'mp4'].includes(fileFormat)"
        class="content-kit-play" @click="showPreview">
          <v-icon large class="play-btn">mdi-play</v-icon>
        </div>
        <div
          :class="{'no-image': !validImage}"
          class="card-image d-flex justify-center align-center"
          @click="showPreview"
        >
          <template v-if="item.fileType === '1'">
            <img
              :src="getOptimizedS3ImageURL(cardImage, { width: 200, height: 200 })"
              alt="no-image"
              :class="{'default-image': !validImage}"
            />
          </template>
          <template v-else-if="item.fileType === '2'">
            <div class="card-image d-flex justify-center align-center"
            >
              <img
                :src="getOptimizedS3ImageURL(poster, { width: 200, height: 200 })"
                :class="{'default-image': !item.poster}"
              />
            </div>
            <!-- <div class="card-image d-flex justify-center align-center" v-else>
              <img
              :src="getOptimizedS3ImageURL(cardImage, { width: 200, height: 200 })"
              alt="no-image"
              :class="{'default-image': !validImage}"
            /> -->
            <!-- </div> -->
          </template>
          <template v-else>
            <div
              class="long-copy d-flex justify-center align-center w-100 h-100"
            >
              <img v-if="fileFormat === 'pdf'"
                :src="require(`@/assets/svg/theme/pdf.svg`)" class="default-image"
              />
              <img v-else :src="require(`@/assets/svg/theme/doc.svg`)" class="default-image" />
              <!-- <embed
                :src="item.file"
                type="application/pdf" width="100%" height="140px"
                v-on:click="showPreview"
                id="embed-tag"
                ref="embed-tag"
              /> -->
            </div>
          </template>
        </div>
      </div>
      </v-hover>
      <div>
        <div
          class='card-info'
          v-if="showInfo"
          v-click-outside="onClickOutsideInfo"
        >
          <v-card tile flat outlined>
            <v-card-title  class='pt-2 pb-0 pl-2 pr-0 text-capitalize'>
              <v-row>
                <v-col cols=9>
                  <span class="notranslate">{{item.name}}</span>
                </v-col>
                <v-col cols=3 class=" d-flex justify-end">
                  <v-icon small class="pr-5" @click="showInfo = false">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="ml-2 mr-6"> </v-divider>
            <v-card-text class="pa-2 font-weight-medium black--text">
              <v-row class="ma-0">
                <v-col cols=6> Type: </v-col>
                <v-col cols=6 class="pl-1">{{fileType}}</v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols=6> File Type: </v-col>
                <v-col cols=6 class="pl-1 text-uppercase">{{fileFormat}}</v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols=6> Uploaded by: </v-col>
                <v-col cols=6 class="pl-1 name notranslate">{{creativeName}}</v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols=6> Date: </v-col>
                <v-col cols=6 class="pl-1">{{lastModifiedDate}}</v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols=6> Size: </v-col>
                <v-col cols=6 class="pl-1">{{readableBytes}} </v-col>
              </v-row>
              <v-row class="ma-0" v-if="item.fileType === '1' && validImage">
                <v-col cols=6> Dimensions: </v-col>
                <v-col cols=6 class="pl-1">{{item.dimensions}}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div @mouseup="checkStatus()"
    class="card-name row ma-0">
      <BaseInput
        :mode="rename"
        v-model="itemName"
        :hide-details="true"
        :clickOutsideCallback="updateName"
        @keydown.enter="updateName"
        @inputActive="inputStatus"
        autofocus
        class="w-100"
      >
        <div class="title mb-1 notranslate"> {{item.name}} </div>
        <div class="item-caption" v-if="item.caption"> {{item.caption}} </div>
        <template v-if="socialTagNames">
          <v-divider class="my-2"> </v-divider>
          <div class="item-tags"> {{socialTagNames}} </div>
        </template>
      </BaseInput>
    </div>
    <v-container
      v-if="showMobileInfo"
    >
      <Modal
        :modal="showMobileInfo"
      >
        <div class="card-info">
          <v-card tile flat outlined>
            <v-card-title  class='pt-2 pb-0 pl-2 pr-0 text-capitalize'>
              <v-row>
                 <v-col cols=9 class="py-0 d-flex align-center">
                  <span class="notranslate">{{item.name}}</span>
                 </v-col>
                 <v-col cols=3 class=" d-flex justify-end">
                  <v-icon small class="pr-4" @click="showMobileInfo = false">mdi-close</v-icon>
                 </v-col>
              </v-row>
            </v-card-title>
            <v-divider class="ml-2 mr-6"> </v-divider>
            <v-card-text class="pa-2 font-weight-medium black--text">
              <v-row class="ma-0">
                <v-col cols=6> Type: </v-col>
                <v-col cols=6 class="pl-1">{{fileType}}</v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols=6> File Type: </v-col>
                <v-col cols=6 class="pl-1 text-uppercase">{{fileFormat}}</v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols=6> Uploaded by: </v-col>
                <v-col cols=6 class="pl-1 name notranslate">{{creativeName}}</v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols=6> Date: </v-col>
                <v-col cols=6 class="pl-1">{{lastModifiedDate}}</v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col cols=6> Size: </v-col>
                <v-col cols=6 class="pl-1">{{readableBytes}} </v-col>
              </v-row>
              <v-row class="ma-0" v-if="item.fileType === '1' && validImage">
                <v-col cols=6> Dimensions: </v-col>
                <v-col cols=6 class="pl-1">{{item.dimensions}}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </Modal>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import vClickOutside from 'v-click-outside';
import { isMobile, isTablet } from 'mobile-device-detect';
import Modal from '@/components/common/Modal';
// import VideoPlayer from '@/components/common/VideoPlayer';
import { getOptimizedS3ImageURL } from '@/helpers/';
import Info from '@/components/common/svg/Info';
import BaseInput from '../common/BaseInput';

export default {
  /* eslint-disable global-require */
  name: 'BaseCard',
  components: {
    BaseInput,
    Modal,
    Info,
    // VideoPlayer,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    activeClass: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    hasInfo: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onArchive: {
      type: Function,
      default: () => {},
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    onDownload: {
      type: Function,
      default: () => {},
    },
    isProjectActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['modal']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject']),
    ...mapGetters('contentKit', ['currentKitProjectDeliverables']),
    device() {
      return isMobile || isTablet;
    },

    role() {
      return _.get(this.userDetails, 'role.name');
    },
    canUpdate() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return ['creative', 'executive', 'producer'].includes(this.role) && assigned;
    },

    customer() {
      return ['client', 'member'].includes(this.role);
    },
    agency_customer() {
      return _.includes(['agency_owner', 'agency_member'], this.role);
    },
    fileType() {
      if (this.item.fileType === '1') {
        return 'Photo';
      }
      return this.item.fileType === '2' ? 'Video' : 'Long Form Copy';
    },
    fileFormat() {
      if (_.split(this.item.fileFormat, '/')[1] === 'x-subrip') {
        return 'srt';
      }
      return _.split(this.item.fileFormat, '/')[1];
    },
    image() {
      return this.item.smallImage || this.item.file;
    },
    validImage() {
      return this.image && this.isFileImage(this.item.fileFormat);
    },
    cardImage() {
      return this.validImage ? this.image : this.defaultImage;
    },
    poster() {
      if (this.item.poster) {
        return this.item.poster;
      }
      if (this.fileFormat === 'mp4') {
        return require('@/assets/svg/theme/mp4.svg');
      }
      return require('@/assets/svg/theme/attach.svg');
    },
    defaultImage() {
      if (this.fileFormat === 'ai') {
        return require('@/assets/svg/theme/ai.svg');
      }
      if (this.fileFormat === 'psd') {
        return require('@/assets/svg/theme/psd.svg');
      }
      if (this.fileFormat === 'srt') {
        return require('@/assets/svg/theme/srt.svg');
      }
      return require('@/assets/svg/theme/project-banner-default.svg');
    },
    creativeName() {
      const creative = _.get(this.item, 'uploadedUser', {});
      return `${creative.firstName} ${creative.lastName}`;
    },
    socialTagNames() {
      return _.compact(_.map(this.item.socialTags, (tag) => tag.name.trim())).join(', ');
    },
    lastModifiedDate() {
      return moment(this.item.updatedAt).format('LL');
    },
    readableBytes() {
      const i = Math.floor(Math.log(this.item.fileSize) / Math.log(1024));
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      return `${(this.item.fileSize / (1024 ** i)).toFixed(2) * 1} ${sizes[i]}`;
    },
    deliverableName() {
      const projDeliverable = _.find(this.currentKitProjectDeliverables, ['deliverableId', this.item.deliverableId]);
      if (projDeliverable) {
        return `${_.get(projDeliverable.deliverable, 'category.name', '')}
                (${projDeliverable.deliverable.name})`;
      }
      return '';
    },
  },
  data() {
    return {
      activeMenu: false,
      showInfo: false,
      rename: 'normal',
      itemName: '',
      itemExtention: '',
      clickedInBox: false,
      inputActive: false,
      showMobileInfo: false,
    };
  },
  mounted() {
    const index = this.item.name.lastIndexOf('.');
    this.itemName = this.item.name.substr(0, index);
    this.itemExtention = this.item.name.substr(index + 1);
  },
  methods: {
    ...mapActions(['setModal']),
    ...mapActions('contentKit', ['updateFavoriteContentKits', 'unfavoriteContentKits']),
    getOptimizedS3ImageURL,
    isFileImage(format) {
      return format && ['jpg', 'png', 'jpeg', 'gif'].includes(format.split('/')[1]);
    },
    checkStatus() {
      this.clickedInBox = true;
    },
    togglefavoriteIcon(id) {
      this.item.favorite = !this.item.favorite;
      if (this.item.favorite) {
        this.updateFavoriteContentKits(id);
      } else {
        this.unfavoriteContentKits(id);
      }
    },
    inputStatus(status) {
      this.inputActive = status;
    },
    toggleInfo() {
      if (this.device) {
        this.showMobileInfo = true;
      } else {
        this.showInfo = true;
      }
    },
    updateName(event) {
      if (this.inputActive && this.clickedInBox && event.code !== 'Enter') {
        return;
      }
      if (this.nameChanged()) {
        this.$emit('fileRename', { id: this.item.id, name: `${this.itemName}.${this.itemExtention}` });
      }
      this.rename = 'normal';
    },
    archiveFile() {
      this.$emit('onArchive', { id: this.item.id });
    },
    deleteFile() {
      this.$emit('onDelete', { contentKitId: this.item.contentKitId, id: this.item.id, status: this.item.status });
    },
    toggleMenu() {
      this.activeMenu = !this.activeMenu;
    },
    onClickOutsideInfo() {
      this.showInfo = false;
    },
    showPreview() {
      this.$emit('onPreview', this.item.id);
    },
    nameChanged() {
      const nameWihoutExt = this.item.name.substr(0, this.item.name.lastIndexOf('.'));
      if (this.itemName.length) {
        return nameWihoutExt !== this.itemName;
      }
      this.itemName = nameWihoutExt;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    border: none !important;
    .card-body {
      background: $neutral5;
      height: 140px;
      position: relative;
      .card-info {
        box-shadow: 0 2px 2px 0 $silver;
        width: 90%;
        overflow-y: auto;
        position: absolute;
        right: 5%;
        top: 20%;
        .v-card__title, .v-card__text {
          font-size: 12px !important;
        }
        .v-card__title {
          line-height: normal;
        }
        .name {
          font-weight: normal;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      &.hover {
        opacity: 0.5;
      }
      .menu {
        // &.mobile {
        //   bottom: -35px;
        //   top: auto !important;
        // }
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 5;

        .menu-btn {
          background: rgba(0, 0, 0, 0.2);
        }
      }
      .deliver-status {
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        right: 32px;
        top: 8px;
        z-index: 2;
      }
      .card-image {
        height: 140px;
        width: 100%;
        cursor: pointer;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          &.default-image {
            width: 80px !important;
            height: 80px !important;
          }
        }
        .video-content, .long-copy {
          cursor: pointer;
          .default-image {
            cursor: pointer;
          }
        }
        .video-player {
          margin: 0 auto;
          max-width: 100%;
        }
      }
    }
    .card-name {
      font-size: 16px;
      font-weight: bold;
      padding: 15px 8px;
      background-color: white;
      .title {
        font-size: 13px !important;
        letter-spacing: -0.1px !important;
        line-height: 1.5em;
        word-break: break-word;
      }
      .item-caption, .item-tags{
        letter-spacing: -0.09px !important;
        font-size: 12px !important;
        text-align: left;
      }
      .title, .item-caption, .item-tags {
        font-family: $fontFamily1 !important;
        font-weight: normal;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .item-tags{
        color: $neutral7;
      }
    }
  }
  .card-info {
    overflow: hidden;
    white-space: nowrap;
    .col-6 {
      padding: 0;
    }
  }
  .v-list {
    border-radius: 0;
    padding: 0;
  }
  .v-menu__content {
    right: 0 !important;
    left: auto !important;
    top: 30px !important;
    border-radius: 0;
    margin-right: 15px;
  }
  .v-list-item {
    min-height: auto;
    padding: 0;
    .v-btn {
      height: 30px;
      :hover::before {
        opacity: 0.9 !important;
      }
    }
    ::v-deep {
      .v-btn__content {
        font-size: 14px !important;
        font-family: $fontFamily1;
        justify-content: flex-start;
        width: auto;
        .download-icon {
          padding-right: 5px;
        }
      }
    }
  }
  .v-card {
    border-radius: 0 !important;
  }

  ::v-deep .v-input {
    height: 28px;
    margin: 0 !important;
    padding: 0 !important;
    input {
      font-weight: 400;
      padding-bottom: 4px;
      padding-top: 0;
    }
  }
  .card-file {
    opacity: 0.7;
  }
  .card-file:not(.on-hover) {
    opacity: 1;
  }
  ::v-deep .v-dialog {
    margin: 0 !important;
  }
  .content-kit-favourties, .deliverable-icon {
    position: absolute;
    bottom: 5px;
  }
  .content-kit-favourties {
    right: 8px;
  }
  .deliverable-icon {
    left: 8px;
  }
  .star-outline {
    -webkit-text-stroke: 1.8px #F49C14;
    color: transparent!important;
    font-size: 20px !important;
  }
  .star-fill {
    color: #F4C40C;
    font-size: 24px;
  }
  .content-kit-play {
    position: absolute;
    .play-btn {
      background: rgba(0, 0, 0, 0.4);
      color: white;
      cursor:pointer;
    }
  }
  .delete-icon {
    padding-right: 5px;
    font-size: 20px;
  }
</style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.items.length},[(_vm.items.length)?_c('div',[_c(VOverlay,{attrs:{"value":_vm.isLoading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.showItemsInGrid)?_c(VRow,{staticClass:"ma-0 content-kit-files-grid d-flex align-stretch"},_vm._l((_vm.items),function(item){return _c(VCol,{key:("content-kit-" + (item.id)),staticClass:"pt-0 pb-1 mb-5 file-padding",attrs:{"cols":"6","md":"4","lg":"3","xl":"2"}},[_c('div',{staticClass:"content-kit-file-container",class:{active: _vm.isSelected(item.id)}},[_c(VCheckbox,{staticClass:"multi-check",attrs:{"value":item.id,"dense":"","off-icon":"mdi-checkbox-blank-circle-outline","on-icon":"mdi-checkbox-marked-circle"},on:{"change":function($event){return _vm.$emit('selected', $event, _vm.fileType)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('content-kit-file',{staticClass:"content-kit-file",attrs:{"item":item,"activeClass":{ active: _vm.isSelected(item.id) },"isProjectActive":_vm.isProjectActive,"section":_vm.section},on:{"fileRename":_vm.renameFile,"onArchive":_vm.archiveFile,"onDelete":_vm.deleteFile,"onDownload":function($event){return _vm.downloadFiles(item.id)},"onPreview":_vm.previewClick}})],1)])}),1):_c(VRow,{staticClass:"ma-0 content-kit-files-list"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,class:{active: _vm.checkSelected(item.id)},on:{"click":function($event){return _vm.toggleSelected(item.id)}}},[_c('td',{attrs:{"width":"40%"}},[_c('div',{staticClass:"item-name notranslate"},[(item.fileType == '1')?_c('img',{attrs:{"src":require('@/assets/svg/theme/project-banner-default.svg'),"width":"24","height":"24"}}):_vm._e(),(_vm.isProjectActive && _vm.canUpdate)?[_c(VEditDialog,{on:{"open":function($event){return _vm.setCurrentItemName(item)},"save":function($event){return _vm.updateFileName(item)},"cancel":function () {},"close":function($event){return _vm.updateFileName(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c(VTextField,{attrs:{"label":"Edit","single-line":""},model:{value:(_vm.currentItemName),callback:function ($$v) {_vm.currentItemName=$$v},expression:"currentItemName"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.name)+" ")])]:[_vm._v(" "+_vm._s(item.name)+" ")]],2)]),_c('td',{staticClass:"notranslate",attrs:{"width":"30%"}},[_vm._v(_vm._s(item.uploadedUser.firstName))]),_c('td',{attrs:{"width":"20%"}},[_vm._v(_vm._s(_vm.formatedDate(item.updatedAt)))]),_c('td',{staticClass:"text-end",attrs:{"width":"10%"}},[_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","height":"auto","width":"auto"}},on),[_c(VIcon,{attrs:{"dense":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[(item.status !== '2' && _vm.isProjectActive && _vm.canUpdate)?_c(VListItem,[_c(VListItemTitle,[_c(VBtn,{staticClass:"w-100",attrs:{"text":""},on:{"click":function($event){return _vm.archiveFile(item)}}},[_c('img',{staticClass:"download-icon",attrs:{"src":require("@/assets/svg/theme/archive.svg"),"width":"24","height":"14"}}),_vm._v(" Archive ")])],1)],1):_vm._e(),_c(VListItem,[_c(VListItemTitle,[_c(VBtn,{staticClass:"w-100",attrs:{"text":""},on:{"click":function($event){return _vm.downloadFiles(item.id)}}},[_c('img',{staticClass:"download-icon",attrs:{"src":require("@/assets/svg/theme/download-small.svg"),"width":"24","height":"14"}}),_vm._v(" Download ")])],1)],1)],1)],1)],1)])}),0)]}}],null,false,687977722)})],1)],1),(_vm.previewModal)?_c(VContainer,{attrs:{"fluid":""}},[_c('Modal',{attrs:{"persistent":"","fullscreen":"","modal":_vm.previewModal,"content-class":"main-modal preview-modal full-screen","width":"80%","scrollable":""}},[_c('content-kit-file-preview',{attrs:{"prevItemIndex":_vm.prevIndex,"nextItemIndex":_vm.nextIndex,"item":_vm.selectedItem,"userRole":_vm.role,"src":_vm.src,"numPages":_vm.numPages,"pdfLoading":_vm.pdfLoading},on:{"close-modal":function($event){_vm.previewModal = false},"download":function($event){return _vm.downloadFiles(_vm.selectedItem.id)},"onNavigate":_vm.navigate}})],1)],1):_vm._e()],1):_c('div',[(_vm.activeFilter)?_c(VCol,{staticClass:"pt-0 file-padding"},[_vm._v(" Sorry, no results found! Please try searching for something else or applying a different filter. ")]):_c(VCol,{staticClass:"pt-0 pb-4 file-padding"},[_vm._v(" Uh oh. You don't have any files uploaded in this section ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template >
  <div :key="items.length">
    <div v-if="items.length">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row class="ma-0 content-kit-files-grid d-flex align-stretch" v-if="showItemsInGrid">
        <v-col cols=6 md=4 lg=3 xl=2 class="pt-0 pb-1 mb-5 file-padding"
          v-for="(item) in items" :key="`content-kit-${item.id}`">
          <div class="content-kit-file-container" :class="{active: isSelected(item.id)}">
            <v-checkbox
              v-model="selected"
              :value="item.id"
              dense
              class='multi-check'
              off-icon="mdi-checkbox-blank-circle-outline"
              on-icon="mdi-checkbox-marked-circle"
              @change="$emit('selected', $event, fileType)"
            ></v-checkbox>
            <content-kit-file
              :item="item"
              @fileRename="renameFile"
              @onArchive="archiveFile"
              @onDelete="deleteFile"
              @onDownload="downloadFiles(item.id)"
              :activeClass="{ active: isSelected(item.id) }"
              :isProjectActive="isProjectActive"
              :section="section"
              @onPreview="previewClick"
              class="content-kit-file"
            />
          </div>
        </v-col>
      </v-row>
      <v-row  class="ma-0 content-kit-files-list" v-else>
        <v-col cols=12>
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            hide-default-footer
            disable-pagination
          >
            <template  v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  @click="toggleSelected(item.id)"
                  :class="{active: checkSelected(item.id)}"
                >
                  <td  width="40%">
                    <div class="item-name notranslate">
                      <img
                        :src="require('@/assets/svg/theme/project-banner-default.svg')"
                        width=24 height=24 v-if="item.fileType == '1'">
                      <template v-if="isProjectActive && canUpdate">
                        <v-edit-dialog
                          @open="setCurrentItemName(item)"
                          @save="updateFileName(item)"
                          @cancel="() => {}"
                          @close="updateFileName(item)"
                        > {{ item.name }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="currentItemName"
                              label="Edit"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-else> {{ item.name }} </template>
                    </div>
                  </td>
                  <td width="30%" class="notranslate">{{item.uploadedUser.firstName}}</td>
                  <td width="20%">{{formatedDate(item.updatedAt)}}</td>
                  <td class="text-end" width="10%">
                    <v-menu bottom left offset-y offset-x>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          height="auto"
                          width="auto"
                        >
                          <v-icon dense>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                        v-if="item.status !== '2' && isProjectActive && canUpdate">
                          <v-list-item-title>
                            <v-btn text class="w-100" @click="archiveFile(item)">
                              <img
                                src="@/assets/svg/theme/archive.svg"
                                class="download-icon"
                                width=24
                                height=14
                              />
                              Archive
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <v-btn text class="w-100" @click="downloadFiles(item.id)">
                              <img
                                src="@/assets/svg/theme/download-small.svg"
                                class="download-icon"
                                width=24
                                height=14
                              />
                              Download
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-container
        fluid
        v-if="previewModal"
      >
        <Modal
          persistent
          fullscreen
          :modal="previewModal"
          content-class="main-modal preview-modal full-screen"
          width="80%"
          scrollable
        >
          <content-kit-file-preview
            :prevItemIndex="prevIndex"
            :nextItemIndex="nextIndex"
            :item="selectedItem"
            @close-modal="previewModal = false"
            @download="downloadFiles(selectedItem.id)"
            @onNavigate="navigate"
            :userRole="role"
            :src="src"
            :numPages="numPages"
            :pdfLoading="pdfLoading"
          />
        </Modal>
      </v-container>
    </div>
    <div v-else>
      <v-col
        v-if="activeFilter"
        class="pt-0 file-padding"
      >
      Sorry, no results found!
      Please try searching for something else or applying a different filter.
      </v-col>
      <v-col class="pt-0 pb-4 file-padding" v-else>
        Uh oh. You don't have any files uploaded in this section
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import pdf from 'vue-pdf';
import Modal from '@/components/common/Modal';
import ContentKitFile from './ContentKitFile';
import ContentKitFilePreview from './ContentKitFilePreview';

export default {
  components: {
    ContentKitFile,
    ContentKitFilePreview,
    Modal,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    dimensions: {
      type: Array,
      default: () => [],
    },
    socialTags: {
      type: Array,
      default: () => [],
    },
    favoriteType: {
      type: Boolean,
      default: false,
    },
    previewItems: {
      type: Array,
      default: () => [],
    },
    section: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      default: 'photo',
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showItemsInGrid: {
      type: Boolean,
      default: true,
    },
    isProjectActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('project', ['currentProject']),
    ...mapGetters(['contentKitFilters']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    canUpdate() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return ['executive', 'creative', 'producer'].includes(this.role)
        && assigned;
    },
    activeFilter() {
      return _.get(this.contentKitFilters, 'search', '').length > 2
        || _.get(this.contentKitFilters, 'selectedTypes', []).length > 0
        || _.get(this.contentKitFilters, 'selectedDimensions', []).length > 0
        || _.get(this.contentKitFilters, 'selectedPlatforms', []).length > 0;
    },
    contentKitId() {
      return this.$route.params.contentKitId || this.$route.query.contentKitId;
    },
  },
  data() {
    return {
      selected: this.selectedItems,
      pdfLoading: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Owner', value: 'uploadedUser.firstName' },
        { text: 'Last Modified', value: 'updatedAt' },
        { text: '', value: '' },
      ],
      currentItemName: '',
      previewModal: false,
      selectedItem: {},
      prevIndex: null,
      nextIndex: null,
      src: '',
      numPages: 0,
    };
  },
  watch: {
    selectedItems() {
      this.selected = this.selectedItems;
    },
  },
  methods: {
    ...mapActions('contentKit', ['updateContentKitFile', 'downloadContentKitFiles', 'archiveContentKitFiles']),
    isSelected(itemId) {
      return _.includes(this.selected, itemId);
    },
    renameFile(file) {
      this.updateContentKitFile({
        contentKitId: this.contentKitId,
        payload: { contentKitFileId: file.id, name: file.name },
      });
    },
    updateFileName(file) {
      const nameWihoutExt = file.name.substr(0, file.name.lastIndexOf('.'));
      if (nameWihoutExt !== this.currentItemName) {
        const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
        this.renameFile({ id: file.id, name: `${this.currentItemName}.${ext}` });
      }
    },
    checkSelected(itemId) {
      return _.includes(this.selected, itemId);
    },
    toggleSelected(itemId) {
      if (!this.checkSelected(itemId)) {
        this.selected.push(itemId);
      } else {
        this.selected.splice(this.selected.indexOf(itemId), 1);
      }
      this.$emit('selected', this.selected, this.fileType);
    },
    formatedDate(timestamp) {
      return moment(timestamp).format('LL');
    },
    async downloadFiles(fileId) {
      const file = _.find(this.items, ['id', fileId]);
      const result = await this.downloadContentKitFiles({
        contentKitId: file.contentKitId,
        fileIds: [fileId],
      });
      if (result.success) {
        _.map(result.data, (data) => {
          window.open(data.url, '_blank');
        });
      }
    },
    archiveFile(file) {
      this.archiveContentKitFiles({
        contentKitId: this.contentKitId,
        payload: { contentKitFileIds: [file.id] },
      });
    },
    deleteFile(file) {
      this.archiveContentKitFiles({
        contentKitId: file.contentKitId,
        payload: { contentKitFileIds: [file.id], deletePermanently: 1 },
        status: file.status,
      });
    },
    itemName(item) {
      return item.name.substr(0, item.name.lastIndexOf('.'));
    },
    setCurrentItemName(item) {
      this.currentItemName = this.itemName(item);
    },
    previewClick(id) {
      this.previewModal = true;
      const itemIndex = _.findIndex(this.previewItems, ['id', id]);
      this.selectedItem = this.previewItems[itemIndex];
      this.prevIndex = itemIndex - 1;
      this.nextIndex = itemIndex === this.previewItems.length - 1 ? -1 : itemIndex + 1;
      this.getPdfFullFileView(this.selectedItem);
    },
    navigate(index) {
      this.selectedItem = Object.assign({}, this.previewItems[index]);
      this.prevIndex = index - 1;
      this.nextIndex = index === this.previewItems.length - 1 ? -1 : index + 1;
      this.getPdfFullFileView(this.selectedItem);
    },
    getPdfFullFileView(selectedItem) {
      const ext = selectedItem.fileFormat;
      if (ext === 'application/pdf') {
        this.pdfLoading = true;
        const loadingTask = pdf.createLoadingTask(selectedItem.file);
        this.src = loadingTask;
        this.src.promise.then((pdfFile) => {
          this.numPages = pdfFile.numPages;
          this.pdfLoading = false;
        });
      }
    },
  },
};
</script>


<style scoped lang="scss">
  .content-kit-file-container {
    background: white;
    height: 100%;
    position: relative;
    border: solid 1px #edeff2 !important;
    &.active {
      border: solid 1px $lightGreen !important;
    }
  }
  .multi-check {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;
    ::v-deep {
      .v-icon{
        background-color: rgba(0, 0, 0, 0.2);
        width: 14px;
        height: 14px;
        border-radius: 50%;
        font-size: 18px;
        &.mdi-checkbox-blank-circle-outline{
        color: #fff !important;
        }
        &.mdi-checkbox-marked-circle{
          color: $lightGreen !important;
        }
      }
      .v-input--selection-controls__ripple {
        width: 18px;
        height: 18px;
      }
    }
    &.v-input--is-label-active {
      ::v-deep {
        .v-icon{
          background-color: white;
        }
      }
    }
  }
  .v-menu__content {
    border-radius: 0;
    margin-right: 10px;
  }
  .v-list-item {
    min-height: auto;
    padding: 0;
    .v-btn {
      height: 30px;
      :hover::before {
        opacity: 0.9 !important;
      }
    }
    ::v-deep {
      .v-btn__content {
        font-size: 14px;
        justify-content: flex-start;
        width: auto;
        .download-icon, .archive-icon {
          padding-right: 5px;
        }
      }
      .v-btn {
        padding-right: 50px;
      }
    }
  }
  .v-data-table {
    background-color: INHERIT;
    ::v-deep th, td {
      color: black !important;
      font-family: $fontFamily1;
      font-size: 14px !important;
    }
    ::v-deep th {
      font-weight: normal;
      height: auto;
      letter-spacing: -0.11px;
      padding: 0 10px 5px 0;
    }
    ::v-deep td {
      padding: 0 10px 0 0;
    }
    td .item-name {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    tr.active {
      background: #E8EBF6 !important;
    }
  }
  @media (max-width: 600px) {
    .file-padding {
      padding-left: 4px;
    }
  }

</style>

<template>
  <div class="content-kit-overview-container">
    <v-overlay :value="isLoading" z-index=8 >
      <!-- <v-progress-circular indeterminate size="64"></v-progress-circular> -->
      <div class="loading-template"> Please wait while we load the data... </div>
    </v-overlay>

    <template v-if="selectedLength > 0">
      <content-kit-file-actions
        :count="selectedLength"
        @removeSelection="removeSelected()"
        @selectAll="selectAll()"
        @move="showPopup('move')"
        @assign="showPopup('assign')"
        @archive="showPopup('archive')"
        @download="showPopup('download')"
        @release="showPopup('release')"
        @assignToDeliverable="showPopup('assign deliverable')"
        @bulkEdit="showPopup('bulk edit')"
        :showArchive="contentKitType === 1 && isProjectActive && canUpload"
        :canUpdate="canAccess && isProjectActive"
        :filterType="contentKitType"
        :activeContentKit="isContentKitActive"
        :showReleased="allReleased"
        :showAssigned="allAssigned"
      > </content-kit-file-actions>
    </template>

    <div style="position: relative">
      <template v-if="!isLoading">
        <div class="content-kit-files" :class="{'small': $vuetify.breakpoint.smAndBelow}">
          <template v-if="separateByKit">
            <div v-if="Object.keys(contentKitFilesByKit).length">
              <div
                v-for="name in Object.keys(contentKitFilesByKit)"
                :key="`kit-${name}`"
              >
                <content-kit-files-list
                  :items="contentKitFilesByKit[name]"
                  :previewItems="previewItems"
                  :selectedItems="[...selected.photo, ...selected.video, ...selected.copy]"
                  @selected="checkSelected"
                  :fileType="'photo'"
                  :isProjectActive="isProjectActive"
                  :section="'photo'"
                  :search="search"
                  :dimensions="dimensionIds"
                  :socialTags="socialTagIds"
                  :favoriteType="favoriteItemType"
                  :showItemsInGrid="grid"
                />
              </div>
            </div>
            <div v-else>
              <v-row class="ma-0 font-family-2">
                <v-col class="pt-0">
                  Sorry, no results found!
                  Please try searching for something else or applying a different filter.
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-else>
            <v-col cols="12" class="pa-0" v-if="filterType('1')">
              <content-kit-files-list
                :items="contentKitItems('1', false)"
                :previewItems="previewItems"
                :selectedItems="[...selected.photo]"
                @selected="checkSelected"
                :fileType="'photo'"
                :isProjectActive="isProjectActive"
                :section="'photo'"
                :search="search"
                :dimensions="dimensionIds"
                :socialTags="socialTagIds"
                :favoriteType="favoriteItemType"
                :showItemsInGrid="grid"
              />
            </v-col>
            <v-col cols="12" class="pa-0" v-if="filterType('2')">
              <v-row class="ma-0 font-family-2">
                <v-col cols=12 class="pt-0 kit-padding"> Video <v-divider /> </v-col>
              </v-row>
              <content-kit-files-list
                :items="contentKitItems('2', false)"
                :previewItems="previewItems"
                :selectedItems="[...selected.video]"
                @selected="checkSelected"
                :fileType="'video'"
                :isProjectActive="isProjectActive"
                :section="'video'"
                :search="search"
                :dimensions="dimensionIds"
                :socialTags="socialTagIds"
                :favoriteType="favoriteItemType"
                :showItemsInGrid="grid"
              />
            </v-col>
            <v-col cols="12" class="pa-0" v-if="filterType('3')">
              <v-row class="ma-0 font-family-2">
                <v-col cols=12 class="pt-0 kit-padding"> Copy <v-divider /> </v-col>
              </v-row>
              <content-kit-files-list
                :items="contentKitItems('3', false)"
                :previewItems="previewItems"
                :selectedItems="[...selected.copy]"
                @selected="checkSelected"
                :fileType="'copy'"
                :isProjectActive="isProjectActive"
                :section="'copy'"
                :search="search"
                :dimensions="dimensionIds"
                :socialTags="socialTagIds"
                :favoriteType="favoriteItemType"
                :showItemsInGrid="grid"
              />
            </v-col>
          </template>
        </div>
      </template>
    </div>
    <v-snackbar
      v-model="snackbar"
      bottom
      absolute
      left
      text
      :timeout="timeout"
    >
      {{message}}
      <v-btn
        class="undo-btn"
        v-if="undo === false"
        right
        text
        @click="undoOperation()"
      >
        Undo
      </v-btn>
    <v-icon
        dark
        right
        @click="snackbar = false"
      >
        mdi-close
      </v-icon>
    </v-snackbar>
    <v-container
      fluid
      v-if="modal"
    >
    <Modal
      persistent
      content-class="main-modal"
      :modal="modal"
      :width="action === 'bulk edit' ? '500' : '400'"
      >
        <BulkActionModal
          :deliverables="projectDeliverables"
          :count="selectedLength"
          :action="action"
          :contentKits="otherContentKits"
          @close-modal="setModal(false)"
          @confirm="(id, userIds, notes, isCreatives, isNotes) =>
          bulkOperation(action, id, userIds, notes, isCreatives, isNotes)"
        />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import EventBus from '@/helpers/event-bus';
// import Upload from '@/components/common/Upload';
// import BaseInput from '@/components/common/BaseInput';
import Modal from '@/components/common/Modal';
import BulkActionModal from './BulkActionModal';
import ContentKitFileActions from './ContentKitFileActions';
import ContentKitFilesList from './ContentKitFilesList';

export default {
  name: 'ContentKitOverview',
  components: {
    // BaseInput,
    BulkActionModal,
    ContentKitFileActions,
    ContentKitFilesList,
    Modal,
    // Upload,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    separateByKit: {
      type: Boolean,
      dafault: false,
    },
  },
  computed: {
    ...mapGetters(['modal', 'contentKitFilters']),
    ...mapGetters('project', ['currentProject', 'userProjects']),
    ...mapGetters('contentKit', ['userContentKits', 'contentKitFiles', 'currentContentKit']),
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('contentKit', ['dimensions', 'socialTags']),
    contentKitId() {
      return this.$route.params.contentKitId || this.$route.query.contentKitId;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    projectId() {
      return _.get(this.userProjects, '[0].id', '');
    },
    projectName() {
      return this.customer ? _.get(this.userProjects, '[0].name', '') : this.currentProject.name;
    },
    downloadFolderName() {
      return `${this.projectName}_${moment().format('M-D-YYYY')}`;
    },
    amIAssignedToProject() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return assigned;
    },
    canAccess() {
      return _.includes(['admin', 'executive', 'producer'], this.role)
        && this.amIAssignedToProject;
    },
    canUpload() {
      return _.includes(['admin', 'executive', 'creative', 'producer'], this.role)
        && this.amIAssignedToProject;
    },
    isProjectActive() {
      return _.get(this.currentContentKit, 'project.status') === '1';
    },
    isContentKitActive() {
      return _.get(this.currentContentKit, 'status') === '1';
    },
    currentContentKitName() {
      return _.get(this.currentContentKit, 'name', 'content-kit');
    },
    selectedLength() {
      return _.uniq([...this.selected.photo, ...this.selected.caption,
        ...this.selected.video, ...this.selected.copy]).length;
    },
    selectedIds() {
      return _.uniq(
        _.concat(this.selected.photo, this.selected.video,
          this.selected.caption, this.selected.copy),
      );
    },
    filterType() {
      return (type = '1') => {
        if (this.noTypeSelected) return true;
        return _.includes(_.get(this.contentKitFilters, 'selectedTypes'), type);
      };
    },
    noTypeSelected() {
      return _.isEmpty(_.get(this.contentKitFilters, 'selectedTypes', []));
    },
    dimensionIds() {
      return _.get(this.contentKitFilters, 'selectedDimensions', []);
    },
    socialTagIds() {
      return _.get(this.contentKitFilters, 'selectedPlatforms', []);
    },
    favoriteItemType() {
      return this.contentKitFilters.favoriteItems;
    },
    grid() {
      return this.contentKitFilters.gridView;
    },

    filteredContentKits() {
      let { contentKitFiles } = this;
      this.$emit('filters-change', contentKitFiles.length);
      const search = _.get(this.contentKitFilters, 'search', '').toLowerCase();
      if (search.length > 2) {
        contentKitFiles = _.filter(contentKitFiles, (c) => {
          const name = c.name.toLowerCase();
          const caption = c.caption || '';
          return _.includes(name, search) || _.includes(caption.toLowerCase(), search);
        });
      }
      if (this.dimensionIds.length > 0) {
        contentKitFiles = _.filter(contentKitFiles, (item) => _.includes(this.dimensionIds,
          item.dimensionId));
      }
      if (this.socialTagIds.length > 0) {
        contentKitFiles = _.filter(contentKitFiles, (item) => _.intersection(this.socialTagIds,
          _.map(item.socialTags, (x) => x.id)).length > 0);
      }
      if (this.favoriteItemType) {
        contentKitFiles = _.filter(contentKitFiles, ['favorite', true]);
      }
      if (this.contentKitFilters.sortBy === 'recent') {
        if (this.role === 'client' || this.role === 'member') {
          contentKitFiles = _.orderBy(contentKitFiles, 'releasedDate', 'desc');
        } else {
          contentKitFiles = _.orderBy(contentKitFiles, 'createdAt', 'desc');
        }
      }
      return contentKitFiles;
    },
    contentKitFilesByKit() {
      const contentKitFiles = this.previewItems;
      return _.groupBy(contentKitFiles, (file) => file.contentKit.name);
    },
    previewItems() {
      if (this.noTypeSelected) {
        return this.allItems;
      }
      // const items = [];
      const items = _.map(_.sortBy(_.get(this.contentKitFilters, 'selectedTypes', [])), (type) => [...this.contentKitItems(type, false)]);
      return _.flatten(items);
    },
    otherContentKits() {
      // eslint-disable-next-line eqeqeq
      return _.filter(this.userContentKits,
        (kit) => kit.id !== this.contentKitId);
    },
    contentKitState() {
      return Number(_.get(this.currentProject, 'contentKits[0].state', 0));
    },
    allItems() {
      return [...this.contentKitItems('1', false),
        ...this.contentKitItems('2', false),
        ...this.contentKitItems('3', false),
      ];
    },
    copyItems() {
      return [...this.captionItems(), ...this.contentKitItems('3')];
    },
    filter() {
      return [
        this.search,
        this.dimensionIds,
        this.socialTagIds,
        this.favoriteItemType,
      ];
    },
  },
  data() {
    return {
      bulkEditOperation: {},
      selectedDeliverableId: '',
      projectDeliverables: [],
      activeTab: 'all',
      gridView: true,
      selected: {
        photo: [],
        video: [],
        caption: [],
        copy: [],
      },
      filterItems: [{ text: 'Active', value: 1 }, { text: 'Archived', value: 2 }],
      contentKitType: 1,
      search: '',
      action: '',
      snackbar: false,
      undo: false,
      message: '',
      operation: '',
      timeout: 5000,
      filesOperated: [],
      allReleased: false,
      allAssigned: false,
      uniqueId: '',
      // favoriteItemType: false,
      // dimensionIds: '',
      // socialTagIds: [],
    };
  },
  methods: {
    ...mapActions('project', ['getProject', 'updateProject']),
    ...mapActions('contentKit', ['getUserContentKits', 'getCurrentKit', 'getContentKitFiles',
      'updateContentKitFile', 'archiveContentKitFiles', 'assignContentKitFiles',
      'moveContentKitFiles', 'releaseContentKitFiles', 'assignFilesToDeliverable', 'contentKitFilesUpload', 'resetContentKits',
      'downloadContentKitFiles', 'resetContentKitFiles', 'getAllContentKitFiles', 'resetCurrentContentKit', 'bulkEditContentKitFiles']),
    ...mapActions(['setModal']),
    ...mapActions(['setNotification', 'setUploadingItems', 'setDownloadItems']),
    ...mapActions('contentKit', ['getDeliverablesByContentKitId']),
    dimesionsSelected(selected) {
      this.dimensionIds = selected;
    },
    socialTagsSelected(selected) {
      this.socialTagIds = selected;
    },
    contentKitItems(fileType = '1', filterCaptions = true) {
      if (filterCaptions) {
        return _.filter(this.filteredContentKits, { fileType, captionNeeded: false });
      }
      return _.filter(this.filteredContentKits, { fileType });
    },
    releasedFileIds() {
      const releasedFiles = _.filter(this.filteredContentKits, { released: true });
      return _.map(releasedFiles, 'id');
    },
    assignedFileIds() {
      const releasedFiles = _.filter(this.filteredContentKits, { captionNeeded: true });
      return _.map(releasedFiles, 'id');
    },

    captionItems() {
      return _.filter(this.filteredContentKits, { captionNeeded: true });
    },
    removeSelected() {
      this.selected = {
        photo: [],
        video: [],
        caption: [],
        copy: [],
      };
    },
    toggleView() {
      this.gridView = !this.gridView;
    },
    selectAll() {
      this.selected.photo = _.map(_.filter(this.previewItems, { fileType: '1' }), 'id');
      this.selected.video = _.map(_.filter(this.previewItems, { fileType: '2' }), 'id');
      this.selected.copy = _.map(_.filter(this.previewItems, { fileType: '3' }), 'id');
    },
    isSelected(itemId) {
      return _.includes(this.selected, itemId);
    },
    filterReleased(selected) {
      if (!_.difference(selected, this.releasedFileIds()).length) {
        this.allReleased = true;
      } else {
        this.allReleased = false;
      }
    },
    filterAssigned(selected) {
      if (!_.difference(selected, this.assignedFileIds()).length) {
        this.allAssigned = true;
      } else {
        this.allAssigned = false;
      }
    },
    checkSelected(selected, fileType) {
      this.filterReleased(selected);
      this.filterAssigned(selected);
      this.selected[fileType] = selected;
    },
    showPopup(action) {
      this.action = action;
      this.setModal(true);
    },
    bulkOperation(action, id, userIds, notes, isCreatives, isNotes) {
      this.bulkEditOperation = Object.assign({}, {
        userIds,
        notes,
        isCreatives,
        isNotes,
      });
      switch (action) {
        case 'archive':
          return this.archiveFiles(this.selectedIds);
        case 'assign':
          return this.assignFiles(this.selectedIds);
        case 'release':
          return this.releaseFiles(this.selectedIds);
        case 'move':
          return this.moveFiles(this.selectedIds, id);
        case 'download':
          return this.downloadFiles(this.selectedIds);
        case 'assign deliverable':
          return this.assignToDeliverable(this.selectedIds, id);
        case 'bulk edit':
          return this.bulkEditAction(this.selectedIds);
        default:
          return {};
      }
    },
    async archiveFiles(contentKitFileIds) {
      const result = await this.archiveContentKitFiles({
        contentKitId: this.contentKitId,
        payload: { contentKitFileIds },
      });
      if (result.success) {
        this.removeSelected();
        this.setModal(false);
      }
    },
    assignReleaseAndUndo(files, operationToPerform) {
      this.filesOperated = files;
      this.undo = false;
      this.snackbar = true;
      this.setModal(false);
      let payloadObj = {};
      if (this.operation === 'Assign') {
        payloadObj = {
          contentKitFileIds: files, captionNeeded: 'true',
        };
      } else if (this.operation === 'Release') {
        payloadObj = {
          contentKitFileIds: files,
        };
      } else if (this.operation === 'assign deliverable') {
        this.undo = true;
        payloadObj = {
          contentKitFileIds: files,
          projectDeliverableId: this.selectedDeliverableId,
        };
      } else if (this.operation === 'bulk edit') {
        this.undo = true;
        const {
          isCreatives,
          isNotes,
          userIds,
          notes,
        } = this.bulkEditOperation;
        if (isCreatives === true && isNotes === false) {
          payloadObj = {
            contentkit_file_ids: files,
            creative_ids: userIds,
          };
        } else if (isCreatives === false && isNotes === true) {
          payloadObj = {
            contentkit_file_ids: files,
            notes,
          };
        } else {
          payloadObj = {
            contentkit_file_ids: files,
            creative_ids: userIds,
            notes,
          };
        }
      }
      operationToPerform({
        contentKitId: this.contentKitId,
        payload: payloadObj,
      }).then((result) => {
        if (result.success) {
          if (result.uniqueId) {
            this.uniqueId = result.uniqueId;
          }
          this.removeSelected();
          this.setModal(false);
        }
      });
    },
    async assignFiles(contentKitFileIds) {
      const FilesToBeAssignedNow = _.difference(contentKitFileIds, this.assignedFileIds());
      this.message = `Assigned ${this.selectedLength} ${this.selectedLength > 1 ? 'files' : 'file'} to copy`;
      this.operation = 'Assign';
      this.assignReleaseAndUndo(FilesToBeAssignedNow, this.assignContentKitFiles);
    },

    async assignToDeliverable(contentKitFileIds, deliverableId) {
      const assignToDeliverable = contentKitFileIds;
      this.message = `Assigned ${this.selectedLength} ${this.selectedLength > 1 ? 'files' : 'file'} to deliverable`;
      this.operation = 'assign deliverable';
      this.selectedDeliverableId = deliverableId;
      this.assignReleaseAndUndo(assignToDeliverable, this.assignFilesToDeliverable);
    },

    async bulkEditAction(contentKitFileIds) {
      const fileIds = contentKitFileIds;
      this.message = `Edit ${this.selectedLength} ${this.selectedLength > 1 ? 'files' : 'file'}`;
      this.operation = 'bulk edit';
      this.assignReleaseAndUndo(fileIds, this.bulkEditContentKitFiles);
    },

    async releaseFiles(contentKitFileIds) {
      const FilesToBeReleasedNow = _.difference(contentKitFileIds, this.releasedFileIds());
      this.message = `Released ${this.selectedLength} ${this.selectedLength > 1 ? 'files' : 'file'} to ${this.currentProject.name}`;
      this.operation = 'Release';
      this.assignReleaseAndUndo(FilesToBeReleasedNow, this.releaseContentKitFiles);
    },

    async undoOperation() {
      this.undo = true;
      this.message = `${this.operation} cancelled`;
      const filesRelesed = _.uniq(this.filesOperated);
      let operationToPerform;
      let payloadObj = {};
      if (this.operation === 'Release') {
        operationToPerform = this.releaseContentKitFiles;
        payloadObj = {
          contentKitFileIds: filesRelesed, released: 'false', uniqueId: this.uniqueId, state: this.contentKitState,
        };
      } else if (this.operation === 'Assign') {
        operationToPerform = this.assignContentKitFiles;
        payloadObj = { contentKitFileIds: filesRelesed, captionNeeded: 'false' };
      }
      operationToPerform({
        contentKitId: this.contentKitId,
        payload: payloadObj,
      }).then((result) => {
        if (result.success) {
          this.removeSelected();
          this.setModal(false);
          this.filesOperated = [];
        }
      });
      this.snackbar = true;
    },
    async moveFiles(contentKitFileIds, newContentKitId) {
      const selectedFiles = _.filter(this.contentKitFiles, (o) => contentKitFileIds.includes(o.id));
      if (_.some(selectedFiles, 'released')) {
        this.setNotification({
          message: 'You can not move delivered files to other content kits.',
          type: 'error',
        }, { root: true });
        this.setModal(false);
        return;
      }

      const result = await this.moveContentKitFiles({
        contentKitId: this.contentKitId,
        status: this.contentKitType,
        payload: { contentKitFileIds, newContentKitId },
      });
      if (result.success) {
        this.removeSelected();
        this.setModal(false);
      }
    },
    uploadContentKits(files) {
      if (files && files.length) {
        const { contentKitId } = this.$route.params;
        _.forEach(files, (file) => {
          this.setUploadingItems(
            Object.assign({}, { file }, { contentKitId, completed: false, error: null }),
          );
        });
      }
    },
    async downloadFiles(fileIds) {
      let payload = {};
      let folderName = '';
      if (this.customer) {
        payload = {
          projectId: this.projectId,
          fileIds,
        };
        folderName = this.$route.name === 'Library' ? this.downloadFolderName : this.currentContentKitName;
      } else if (this.$route.name === 'Content Kit') {
        payload = {
          contentKitId: this.contentKitId,
          fileIds,
        };
        folderName = this.currentContentKitName;
      } else {
        payload = {
          projectId: this.currentProject.id,
          fileIds,
        };
        folderName = this.downloadFolderName;
      }
      if (fileIds.length === 1) {
        const result = await this.downloadContentKitFiles(payload);
        this.setModal(false);
        window.open(result.data[0].url, '_blank');
        return;
      }
      const result = await this.downloadContentKitFiles(payload);
      const files = _.partition(result.data, ['type', '2']);
      this.setModal(false);
      if (files[1].length > 1) {
        this.setDownloadItems(
          Object.assign({}, { files: files[1] },
            { folderName, completed: false, error: null }),
        );
      }
      _.map(files[0], (file) => {
        this.setDownloadItems(
          Object.assign({}, { files: [file] },
            { folderName: file.fileName, completed: false, error: null }),
        );
      });
      this.removeSelected();
    },
    async getProjectDeliverables() {
      const deliverablesByContentKit = await this.getDeliverablesByContentKitId(
        this.contentKitId,
      );
      this.projectDeliverables = deliverablesByContentKit.contentKit.projectDeliverables;
    },
  },
  watch: {
    contentKitType(newValue) {
      this.removeSelected();
      this.getContentKitFiles({
        contentKitId: this.contentKitId,
        status: newValue,
      });
    },
  },

  async mounted() {
    if (this.contentKitId) {
      this.getProjectDeliverables();
    }
    EventBus.$on('filters-updated', () => {
      this.removeSelected();
    });

    EventBus.$on('active-filter-updated', (status) => {
      this.removeSelected();
      this.getContentKitFiles({
        contentKitId: this.contentKitId,
        status,
      });
    });
  },

  beforeDestroy() {
    this.resetContentKitFiles();
    this.resetContentKits();
    this.resetCurrentContentKit();
    EventBus.$off('filters-updated');
    EventBus.$off('active-filter-updated');
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep.v-snack>.v-snack__wrapper{
    margin-bottom: 40px;
    margin-left: 10px;
  }
  ::v-deep .v-snack__content{
  font-family: $fontFamily1;
    font-size: 16px;
    font-weight: normal;
  }
  .content-kit-overview-container {
    max-height: calc(100vh - 200px);
  }
  .content-kit-files {
    width: 100%;
    overflow: auto;
    max-height: calc(100vh - 150px);
  }


  ::v-deep {
    .v-tabs-bar, .v-tabs-items {
      background-color: $neutral3 !important;
    }

    .v-tabs-bar {
      max-height: 90px;
      padding: 0 12px;
    }

    .v-tabs-items {
      max-height: calc(100vh - 185px);
      overflow: auto;
    }

    .v-tabs-slider-wrapper {
      display: none;
    }

    .v-tab {
      padding: 0 15px;
      font-size: 16px;
      letter-spacing: normal;
      line-height: normal;
      text-transform: capitalize;
      min-width: auto !important;
      margin: 0 15px;
      padding: 0 0 2px 0;
    }

    .v-tab--active {
      font-weight: bold;
      color: $primary3;
      border-bottom: 1px solid $primary3;
    }
    .v-slide-group__prev.v-slide-group__prev--disabled {
      display: none !important;
    }
    .v-text-field input {
      padding: 3px 0;
    }
    .v-input__control {
      height: 32px;
    }
    .v-select__selection--comma {
      font-family: $fontFamily1;
      font-size: 14px !important;
      letter-spacing: -0.28px;
      margin: 3px 4px 3px 0;
    }
    .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
      min-height: 32px;
    }
    .v-select__selections input {
      font-family: $fontFamily1;

      width: 0;
    }
    .upload-button {
      width: 89px;
      height: 28px !important;
      border-radius: 19.5px;
      box-shadow: 0 2px 4px 0 rgba(127, 127, 127, 0.5);
      border: solid 2px $card-title;
      color: $card-title;
      label {
        display: flex;
        justify-content: center;
        cursor: pointer;
        background-color: white;
        border-radius: 19.5px;
      }
    }
  }
  .undo-btn{
    font-size:16px;
    color: $secondary1;
    font-weight:bold;
  }

  .search-input {
    width: 200px;
    ::v-deep .v-input {
      height: 32px;
    }
    ::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) >
      .v-input__control > .v-input__slot {
      min-height: 32px !important;
      border-radius: 6px !important;
      box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07) !important;
    }
  }
  .files-filter {
    width: 150px;
    .v-select {
      border-radius: 6px;
    }
  }
  .dimension-filter {
    width: 135px;
    ::v-deep .v-input__slot {
        input {
            font-family: $fontFamily1;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.25;
        }
    }
    ::v-deep {
      .input-field {
        height: auto;
      }
      .v-select .v-input__control {
        width: 255px;
      }
      .v-input__control {
        font-family: $fontFamily1;
          min-height: 38px !important;
            > .v-input__slot {
                min-height: 38px !important;
            }
      }
      .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
        > .v-input__control > .v-input__slot {
          min-height: 38px !important;
          .v-chip.v-size--default {
            height: auto;
          }
      }
      .v-chip.v-size--default{
        color:$primary2 !important;
        background-color: rgb(229, 231, 250);
        border-radius: 6px;
        height: 26px;
      }
    }
  }
  .fav-btn {
    height: 36px;
    ::v-deep .v-btn__content {
       font-size: 16px;
    }
  }
  .star-outline {
    -webkit-text-stroke: 2px #FFFFFF;
    font-size: 13px !important;
    padding-left:3px!important;
  }
  .star-fill {
    font-size: 20px !important;
  }
  .btn-fill {
    background-color:#65d6eb !important;
    color: white !important;
  }
  .cancel-btn{
      height:10px !important;
      width:10px !important;
      font-weight:normal;
    }
  .loading-template {
    border: solid 1px;
    border-color: #babfc7;
    background: #ececec;
    border-radius: 3px;
    box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);
    color: black;
    padding: 6px;
  }
  @media (min-width: 601px) and (max-width: 1264px) {
    .content-kit-files {
      max-height: calc(100vh - 190px)
    }
  }
  @media (max-width: 600px) {
    .content-kit-files {
      max-height: calc(100vh - 220px);
    }
    .kit-padding {
      padding-left: 4px;
    }
  }
</style>

<template>
  <div>
    <ContentKitOverview
      :isLoading="loading"
      :separateByKit="true"
      :showShared="showShared"
    > </ContentKitOverview>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import ContentKitOverview from '../components/contentKits/ContentKitOverview';

export default {
  components: {
    ContentKitOverview,
  },
  props: {
    showShared: {
      default: false,
    },
  },
  computed: {
    ...mapGetters('project', ['userProjects', 'currentProject']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    customer() {
      return ['client', 'member'].includes(this.role);
    },
    projectId() {
      if (this.customer) {
        return _.get(this.userProjects, '[0].id', null);
      }
      return _.get(this.currentProject, 'id', null);
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('contentKit', ['getAllContentKitFiles']),
  },
  async mounted() {
    this.loading = true;
    if (this.projectId) {
      await this.getAllContentKitFiles(this.projectId);
    }
    this.loading = false;
  },
};
</script>

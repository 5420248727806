<template>
  <div class='content-kit-list'>
    <Loader v-if="fetching" />
    <div v-else>
      <div v-if="Object.keys(contentKitsByYear).length">
        <div
          v-for="year in Object.keys(contentKitsByYear).sort().reverse()"
          :key="`project-${year}`">
          <v-row class="ma-0"> <v-col class="py-0">{{year}}<v-divider/> </v-col></v-row>
          <v-row class="ma-0 pt-4 content-kits-yearly">
            <v-col cols=6 sm=4 lg=3 class="pt-0 pb-1 mb-5"
              v-for="item in orderedContentKits(year)" :key="`content-kit-${item.id}`">
              <base-card
                cardType="contentKit"
                :item="item"
                @click="contentKitOverview($event, item.id)"
                @update="contentKitUpdate"
                @renaming="renamingNow"
                @edit-kit="onEdit(item)"
                :canEdit="canUpdate"
                class="content-kit-container"
              >
              </base-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else class='empty-content-kit-container'>
        <v-img
          :src="require('@/assets/svg/theme/welcome.svg')"
          contain
          class="empty-theme-img"
        />
        <div class="empty-text" v-if="search === ''">
          Uh oh. You don't have any content kits at this time
        </div>
        <div class="empty-text" v-else>
          Sorry, no results found!
          Please check the spelling or try searching for something else.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
import Loader from '@/components/common/Loader';
import BaseCard from '../common/BaseCard';

export default {
  components: {
    BaseCard,
    Loader,
  },
  props: {
    parent: {
      type: String,
      default: '',
    },
    status: {
      type: Number,
      default: 1,
    },
    search: {
      type: String,
      default: '',
    },
    projectId: {
      type: Number,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Function,
      default: () => {},
    },
    loadDone: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('contentKit', ['userContentKits']),
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    contentKitsByYear() {
      let contentKits = this.userContentKits;
      this.$emit('filters-change', contentKits.length);
      if (this.search.length > 2) {
        contentKits = _.filter(contentKits, (c) => _.includes(c.name.toLowerCase(),
          this.search.toLowerCase()));
      }
      return _.groupBy(contentKits, (n) => new Date(n.date).getFullYear());
    },
  },
  data() {
    return {
      currentlyRenaming: false,
      fetching: true,
    };
  },
  watch: {
    async projectId(newValue) {
      if (newValue) {
        // this.fetching = true;
        this.loading();
        await this.getUserContentKits({ projectId: newValue });
        // this.fetching = false;
        this.loadDone();
      }
    },
  },
  methods: {
    ...mapActions('contentKit', ['getUserContentKits', 'updateContentKit', 'resetContentKits']),
    contentKitOverview(e, contentKitId) {
      const projectId = this.projectId || this.$route.query.projectId;
      const navBackProjectId = this.$route.params.projectId;
      if (e.target.id && !this.currentlyRenaming) {
        const businessId = this.$route.query.businessId || this.$route.params.businessId;
        if (['client', 'member', 'agency_owner', 'agency_member'].includes(this.role)) {
          if (this.parent === 'agency_mediaLibrary') {
            const data = {
              step: 2,
            };
            this.$emit('changeStep', data);
            const { agencyName, businessName } = this.$route.query;
            this.$router.push({
              name: 'Business',
              query: {
                agencyName,
                businessName,
                contentKitId,
                businessId,
              },
            });
          } else {
            this.$router.push({ name: 'Client Content Kit', params: { projectId, contentKitId } });
          }
        } else if (['admin', 'executive'].includes(this.role)) {
          if (this.$route.name === 'Business' || this.$route.name === 'Organization') {
            const data = {
              step: 2,
            };
            this.$emit('changeStep', data);
            if (this.$route.name === 'Business') {
              const { agencyName, businessName } = this.$route.query;
              this.$router.push({
                name: 'Business',
                query: {
                  agencyName,
                  businessName,
                  contentKitId,
                  businessId,
                },
              });
            } else {
              this.$router.push({
                name: 'Organization',
                query: {
                  contentKitId,
                  businessId,
                },
              });
            }
          } else {
            this.$router.push({ name: 'Content Kit', params: { projectId, contentKitId }, query: { navBackProjectId } });
          }
        } else {
          this.$router.push({ name: 'Content Kit', params: { projectId, contentKitId }, query: { navBackProjectId } });
        }
      } return false;
    },
    contentKitUpdate(contentKit) {
      this.updateContentKit({ id: contentKit.id, payload: { name: contentKit.name } });
    },
    orderedContentKits(year) {
      return _.sortBy(this.contentKitsByYear[year], (o) => moment(o.date)).reverse();
    },
    renamingNow(status) {
      this.currentlyRenaming = status;
    },
    onEdit(contentKit) {
      this.$emit('onEdit', contentKit);
    },
  },
  async mounted() {
    this.fetching = true;
    const projectId = this.projectId || this.$route.query.projectId;
    if (projectId) {
      await this.getUserContentKits({ projectId, status: this.status });
    }
    this.fetching = false;
  },
  beforeDestroy() {
    this.resetContentKits();
  },
};
</script>

<style scoped lang="scss">
  .content-kit-list {
    max-height: calc(100vh - 160px);
    overflow: auto;
    font-family: $fontFamily1;
    // margin: 0 -12px !important;
    .content-kit-container {
      background: white;
      height: 100%;
    }

    .empty-content-kit-container {
      .empty-theme-img {
        text-align: center;
        margin: 50px auto 0;
        width: 500px;
      }
      .empty-text {
        font-size: 24px;
        text-align: center;
        padding: 20px;
      }
    }
  }
  @media (max-width: 600px) {
    .content-kit-list {
      max-height: calc(100vh - 190px);
    }
  }
  @media (max-width: 700px) {
    .empty-theme-img{
      width: 300px;
    }
  }
</style>
